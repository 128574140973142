export const subjectsContent = {
  cardContent: 'So why settle for ordinary when you have a platform for Innovative Education? Dive into our demo learning rooms. Discover. Learn. Enjoy.',
  buttons: ['SCIENCE', 'MATHS', 'SOCIAL'],
  modalContent: {
    SCIENCE: {
      left: {
        title: 'Explore the World of Science',
        paragraphs: [
          'Dive into the fascinating world of scientific discovery and understanding.',
          'Our science curriculum covers physics, chemistry, biology, and environmental science, providing a comprehensive foundation for future scientists and curious minds alike.'
        ]
      },
      right: {
        paragraphs: ['Connect with us to get Access'],
        // photos: [
        //   '/path/to/science1.jpg',
        //   '/path/to/science2.jpg',
        //   '/path/to/science3.jpg',
        //   '/path/to/science4.jpg',
        //   '/path/to/science5.jpg',
        //   '/path/to/science6.jpg'
        // ],
        description: 'Engage in hands-on experiments, virtual labs, and cutting-edge research topics that bring science to life.'
      }
    },
    MATHS: {
      left: {
        title: 'Master the Language of Numbers',
        paragraphs: [
          'Unlock the power of mathematical thinking and problem-solving skills.',
          'Our mathematics curriculum covers algebra, geometry, calculus, and statistics, providing a solid foundation for analytical and quantitative reasoning.'
        ]
      },
      right: {
        paragraphs: ['Connect with us to get Access'],
        // photos: [
        //   '/path/to/math1.jpg',
        //   '/path/to/math2.jpg',
        //   '/path/to/math3.jpg',
        //   '/path/to/math4.jpg',
        //   '/path/to/math5.jpg',
        //   '/path/to/math6.jpg'
        // ],
        description: 'Experience interactive problem-solving, visual representations of complex concepts, and real-world applications of mathematics.'
      }
    },
    SOCIAL: {
      left: {
        title: 'Understand Our World and Society',
        paragraphs: [
          'Explore the complexities of human societies, past and present.',
          'Our social studies curriculum encompasses history, geography, economics, and political science, fostering critical thinking and global awareness.'
        ]
      },
      right: {
        paragraphs: ['Connect with us to get Access'],
        // photos: [
        //   '/path/to/social1.jpg',
        //   '/path/to/social2.jpg',
        //   '/path/to/social3.jpg',
        //   '/path/to/social4.jpg',
        //   '/path/to/social5.jpg',
        //   '/path/to/social6.jpg'
        // ],
        description: 'Engage with interactive maps, historical simulations, and contemporary case studies to develop a nuanced understanding of our world.',
        route: ''
      }
    }
  }
};