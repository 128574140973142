export const buttonColors = {
  'MATHS': '#FFBB08',
  'SCIENCE': '#83BC08',
  'SOCIAL': '#08A7F0',
  'Software': '#0B5DAB',
  'TLMs': '#0B5DAB',
  'School Accessories': '#0B5DAB',
  'Teacher\'s Training': '#0B5DAB',
  'DevaNagari': '#008B8B',
  'Learn More': '#008B8B',
  'Careers': '#008B8B',
  'Devanagari B.Ed Programme':'#5645A1',
  'Balaganesh programme':'#5645A1'
};