import React, { useState } from 'react';
import { Paper, TextField, Button, Box, Typography, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProfilePopup = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the email to your backend
    console.log('Registration requested for:', email);
    // Show confirmation message
    setOpenSnackbar(true);
    // Clear the email input
    setEmail('');
    // Close the popup after a short delay
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <Paper elevation={3} sx={{ padding: 3, width: 300 }}>
        <Typography variant="h6" gutterBottom>
          Request Registration
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            type="email"
          />
          <Box sx={{ mt: 2 }}>
            <Button type="submit" variant="contained" fullWidth>
              Request Register
            </Button>
          </Box>
        </form>
      </Paper>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Registration request has been sent!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProfilePopup;