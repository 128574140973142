import React, { useRef , useEffect} from 'react';
import { useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const eventData = {
  'British in India': {
    image: '/textures/britsh_visit.png',
    position: [-120, 3, -17],
    size: 5,
    audio: '/music/British in India.mp3',
    description: "The British first landed in India on August 24, 1608 at the port of Surat, as employees of the East India Company. Queen Elizabeth I granted the company a Royal Charter on December 31, 1600, which gave them a monopoly on trade with the East Indies and the ability to build factories and forts there. The British established trading posts along the east and west coasts of India, and developed English communities around the presidency towns of Calcutta, Bombay, and Madras.The British's power, economic might, and weapons allowed them to slowly expand into the Indian subcontinent. In 1613, Emperor Jahangir allowed the British to build a factory in Surat, and in 1615, the Mughals and the British established a commercial treaty. Around 1622, the East India Company began to create new laws, rules, and regulations for Indian civilians. The company also collected revenue directly as the Diwan, and controlled police and judicial powers by nominating the Deputy Nazim for the Nawab as Nizamat. "
  },
  'Dalhousie and the Doctrine of Lapse': {
    image: '/textures/Dalhousie and the Doctrine of Lapse.jpg',
    position: [-280, 180, -100],
    audio: '/music/Dalhousie and the Doctrine of Lapse.mp3',
    size: 20,
    
    description: "Lord Dalhousie, the Governor-General of India from 1848 to 1856, introduced a policy known as the Doctrine of Lapse. This policy allowed the British East India Company to annex Indian princely states if the ruler died without a male heir. Dalhousie believed that this would expand British territory and influence in India. He used this doctrine to annex several states, including Satara, Jhansi, and Nagpur, which angered many Indians and contributed to the growing resentment towards British rule."
  },
  'Mangal Pandey': {
    image: '/textures/Mangal Pandey.jpeg',
    position: [110, 5, -46],
    size: 5,
    audio: '/music/Mangal Pandey.mp3',
    description: "On March 29, 1857, Mangal Pandey, a soldier in the British East India Company's army, sparked a rebellion against British rule in India. The immediate reason for his attack was the introduction of the new Enfield rifle, which used cartridges rumored to be greased with animal fat, offending both Hindu and Muslim soldiers. Pandey's refusal to use the rifle led to a confrontation with British officers, during which he attacked them. This act of defiance ignited widespread unrest among Indian soldiers and civilians, marking the beginning of the Indian Rebellion of 1857, also known as the Sepoy Mutiny. Following his actions, the rebellion spread across northern India, leading to significant uprisings against British authority, although it was ultimately suppressed by the British forces."
  },
  'The Delhi Attack of 1857': {
    image: '/textures/The Delhi Attack of 1857.jpg',
    position: [-50, 5, -125],
    size: 10,
    audio: '/music/The Delhi Attack of 1857.mp3',
    description: "The Delhi attack in 1857 was primarily led by Indian soldiers, known as sepoys, who revolted against British rule. The uprising began on May 10, when sepoys in the British garrison at Meerut mutinied and marched to Delhi, where they rallied support from local civilians and discontented nobles. They captured the city and declared the last Mughal emperor, Bahadur Shah II, as their leader. This marked a significant turning point in the Indian Rebellion of 1857, as Delhi became the center of resistance against British rule.After the initial success, the British launched a brutal campaign to retake the city, which culminated in a fierce siege. By September 1857, British forces regained control of Delhi, leading to widespread reprisals against the rebels and civilians alike. The rebellion ultimately failed to achieve its goals, but it marked a crucial moment in Indian history, sowing the seeds for future movements toward independence."
  },
  'The Kanpur Attack': {
    image: '/textures/The Kanpur Attack.jpg',
    position: [0, 5, -80],
    size: 5,
    description: "The Kanpur attack, which occurred in June 1857, was a significant event during the Indian Rebellion against British rule. The reasons behind the attack included widespread discontent among Indian soldiers and civilians due to the oppressive policies of the British, particularly the greased cartridges controversy that sparked the uprising. In Kanpur, sepoys, along with local civilians, besieged the British garrison and took control of the city. The consequences were severe; after a brutal siege, British forces eventually recaptured Kanpur in July, leading to a violent retaliation against the rebels. The British executed many captured rebels and civilians, and the brutal suppression of the uprising in Kanpur served as a stark warning to other regions, solidifying British resolve to maintain control over India."
  },
  'The Lucknow Attack': {
    image: '/textures/The Lucknow Attack.jpg',
    position: [15, 5, -90],
    size: 5,
    description: "The Lucknow attack took place in 1857 as part of the Indian Rebellion against British rule. The reasons behind the attack were similar to those in other regions, with sepoys and civilians rebelling against the perceived injustices and oppressive policies of the British East India Company. In Lucknow, the capital of the state of Oudh (Awadh), the rebellion was led by Begum Hazrat Mahal, who seized control of the city and declared her son as the rightful ruler. The consequences were severe, as the British launched a massive military campaign to retake Lucknow. After a prolonged siege and heavy fighting, the British eventually regained control of the city in March 1858. The suppression of the rebellion in Lucknow was marked by widespread violence and reprisals against the rebels and civilians, further strengthening the British grip on power in the region."
  },
  'Annexation of Jhansi': {
    image: '/textures/Annexation of Jhansi.jpeg',
    position: [-46, 5, -73],
    size: 10,
    description: "In 1853, Lord Dalhousie, the Governor-General of India, annexed the princely state of Jhansi under the Doctrine of Lapse, as the ruler, Gangadhar Rao, had died without a male heir. This action angered many Indians, including Rani Lakshmi Bai, the widow of Gangadhar Rao, who refused to accept the annexation and led a rebellion against the British. During the Indian Rebellion of 1857, Rani Lakshmi Bai emerged as a prominent leader, defending Jhansi against the British forces. Although the rebellion in Jhansi was eventually suppressed, the fierce resistance and the heroic actions of Rani Lakshmi Bai made her a symbol of Indian nationalism and resistance against colonial rule. The annexation of Jhansi and the subsequent uprising contributed to the growing resentment among Indians towards the British East India Company's policies, which ultimately led to the outbreak of the Indian Rebellion of 1857."
  },
  'Bihar Attack: Impact': {
    image: '/textures/Bihar Attack Impact.jpg',
    position: [50, 5, -73],
    size: 5,
    description: "The Bihar attack, particularly during the Indian Rebellion of 1857, had significant repercussions for both the local population and British colonial authorities. The uprising in Bihar was marked by widespread violence as sepoys and civilians revolted against British rule, leading to the attack on British garrisons and the release of prisoners from jails. This rebellion not only disrupted British control in the region but also instigated a harsh crackdown by colonial forces, resulting in severe reprisals against suspected rebels and their supporters. The impact included a heightened sense of fear and distrust among the British, leading to stricter military and administrative measures in the region. Additionally, the rebellion highlighted the growing discontent among Indians, which would eventually contribute to the larger independence movement against British colonial rule in the years to come."
  },
  'East India Company to British Raj': {
    image: '/textures/East India Company to British Raj.png',
    position: [570, 230, -80],
    size: 10,
    description: "The Indian Rebellion of 1857 marked a turning point in the history of British rule in India, leading to the dissolution of the East India Company and the establishment of direct British governance, known as the British Raj. The reasons behind this transition were multifaceted, stemming from the growing discontent among Indians due to the Company's oppressive policies, the annexation of princely states, and the perceived threat to religious and cultural practices. The rebellion itself, with its widespread violence and challenges to British authority, convinced the British government that a more direct and centralized form of control was necessary. In the aftermath of the rebellion, the British Crown assumed direct control over India, initiating a series of reforms aimed at addressing some of the grievances that had fueled the uprising, while also strengthening colonial rule through the expansion of the civil service, the army, and the implementation of new laws and administrative structures."
  },
  'Indian National Congress (1885)': {
    image: '/textures/Indian National Congress (1885).jpg',
    position: [-110, 5, 2],
    size: 10,
    description: "The Indian National Congress (INC) was founded in 1885 by a group of Indian nationalists, with Allan Octavian Hume playing a pivotal role in its establishment. The primary purpose of the INC was to create a platform for educated Indians to express their grievances against British colonial rule and to seek greater political representation and reforms. The formation of the Congress was also influenced by the 'safety valve theory,' which suggested that the British established the organization to channel rising Indian nationalism and prevent more radical uprisings. The INC aimed to unite Indians across different regions and communities to advocate for political and social changes, ultimately becoming a significant force in the struggle for India's independence from British rule."
  },
  'The Partition of Bengal (1905)': {
    image: '/textures/The Partition of Bengal (1905).jpg',
    position: [110, 6, -25],
    size: 10,
    description: "The Partition of Bengal in 1905 was an administrative measure implemented by Lord Curzon, the Viceroy of India, with the intention of dividing the province of Bengal along religious lines. Curzon argued that the partition would improve administrative efficiency, but many Indians saw it as a deliberate attempt to undermine Bengali nationalism and Hindu-Muslim unity. The decision sparked widespread protests and the Swadeshi movement, which called for the boycott of British goods and the promotion of Indian-made products. The partition also led to the emergence of revolutionary terrorism, as some groups resorted to violent methods to resist British rule. The backlash against the partition was so strong that it forced the British government to revoke the decision in 1911, although the damage to Hindu-Muslim relations had already been done, contributing to the eventual partition of India along religious lines in 1947."
  },
  'The Swadeshi Movement:': {
    image: '/textures/The Swadeshi Movement.jpeg',
    position: [100, 6, -25],
    size: 3,
    description: "The Swadeshi Movement, which emerged in response to the partition of Bengal in 1905, was primarily spearheaded by leaders such as Bal Gangadhar Tilak, Bipin Chandra Pal, and Lala Lajpat Rai. The movement aimed to promote Indian goods and boycott British products as a form of protest against British colonial rule and the divisive policies of the British government. It was a call for self-reliance and national pride, encouraging Indians to support local industries and artisans. The aftermath of the movement saw a significant rise in nationalist sentiment across India, leading to increased political activism and the eventual emergence of revolutionary groups. One notable figure, the revolutionary leader and freedom fighter, Khudiram Bose, became a martyr during this period after being executed in 1908 for his involvement in anti-British activities, symbolizing the sacrifices made in the struggle for India's independence."
  },
  'National Congress session in Banaras': {
    image: '/textures/National Congress session in Banaras.jpg',
    position: [27, 5, -70],
    size: 5,
    description: "The Banaras Congress Divide occurred during the Indian National Congress session in Banaras (Varanasi) in 1905, where significant ideological differences emerged between moderates and extremists within the party. The moderates, led by figures like Gopal Krishna Gokhale, advocated for constitutional reforms and gradual change through dialogue with the British, while the extremists, represented by leaders such as Bal Gangadhar Tilak, called for more direct action and self-reliance. The divide was primarily driven by frustrations over the British government's refusal to address Indian demands adequately, particularly after the partition of Bengal. Following this division, the Congress became increasingly fragmented, leading to the rise of more radical nationalist movements in India, as the extremists sought to mobilize the masses and adopt a more confrontational stance against British rule. This ideological split set the stage for the future evolution of the Indian independence movement, ultimately contributing to the emergence of various factions and strategies in the struggle for freedom."
  },
  'The Shimla Deputation ': {
    image: '/textures/The Shimla Deputation.jpg',
    position: [-50, 5, -167],
    size: 5,
    description: "The Shimla Delegation was formed in 1906 by a group of prominent Muslim leaders, including Aga Khan, Nawab Mohsin-ul-Mulk, and other influential figures, in response to the growing concerns among Muslims about their political representation and rights within the Indian nationalist movement. They sought to address the perceived neglect of Muslim interests by the Indian National Congress, which was predominantly led by Hindu leaders. The delegation aimed to present their demands to the British government, advocating for separate electorates and greater political rights for Muslims. This initiative culminated in the formation of the All-India Muslim League later that year, which sought to promote and protect Muslim interests in the political landscape of India. The Shimla Delegation marked a significant moment in the political awakening of Muslims in India, leading to the eventual demand for a separate nation for Muslims, which would play a crucial role in the partition of India in 1947."
  },
  'The 1907 Congress Split ': {
    image: '/textures/The 1907 Congress Split.jpg',
    position: [-115, 3, -20],
    size: 3,
    description: "The Indian National Congress experienced a major split during its annual session in Surat in 1907, dividing the party into two factions: the Moderates and the Extremists. The division was primarily driven by ideological differences, with the Moderates, led by Gopal Krishna Gokhale, favoring gradual reforms and cooperation with the British, while the Extremists, represented by leaders like Bal Gangadhar Tilak and Bipin Chandra Pal, advocated for more aggressive and immediate self-rule. The confrontation at the Surat session turned violent, with both groups clashing over the selection of the Congress president. The British authorities, sensing an opportunity to weaken the nationalist movement, intervened and banned the Congress from meeting for two years. The aftermath of the split saw the Extremists forming their own organizations, such as the Home Rule League, while the Moderates continued to work within the framework of the British system. However, the division within the Congress weakened the overall nationalist movement, allowing the British to maintain control for a longer period. The split also highlighted the need for unity among various factions of the independence struggle, a lesson that would be learned in the years to come."
  },
  'The Morley-Minto Reforms (1909)': {
    image: '/textures/The Morley-Minto Reforms (1909).png',
    position: [120, 33, 0],
    size: 20,
    description: "The Morley-Minto Reforms were introduced in 1909 as a response to growing discontent and demands for political representation in India, particularly following the 1905 partition of Bengal and the subsequent unrest. The British government, recognizing the need to address some of the grievances of Indian nationalists and to quell the rising tide of unrest, appointed John Morley as the Secretary of State for India and Lord Minto as the Viceroy. The reforms aimed to expand the legislative councils and increase Indian representation, allowing for a limited number of elected Indian members in various councils. However, the most significant aspect of the reforms was the introduction of separate electorates for Muslims, which aimed to appease Muslim leaders and ensure their support for British rule. While the Morley-Minto Reforms did grant some political concessions, they ultimately fell short of the demands of Indian nationalists, leading to increased dissatisfaction among both Hindus and Muslims. The reforms also deepened communal divisions, as the separate electorates solidified the notion of distinct Hindu and Muslim identities in politics. In the aftermath, the Indian National Congress and the Muslim League began to adopt more defined and divergent political agendas, setting the stage for future communal tensions and the eventual demand for a separate Muslim state in the years leading up to India's independence."
  },
//   'The Swaraj Movement ': {
//     image: '/textures/india.jpg',
//     position: [120, 20, 0],
//     size: 1,
//     description: "The Swaraj Movement, which emerged in the early 20th century, was primarily led by Bal Gangadhar Tilak and other prominent leaders of the Indian National Congress who advocated for self-rule (Swaraj) as a fundamental goal for India. The movement gained momentum after the Morley-Minto Reforms of 1909, which many nationalists viewed as insufficient in addressing Indian aspirations for greater autonomy. The leaders organized protests, public meetings, and campaigns to raise awareness about the need for self-governance, emphasizing the importance of national identity and unity among Indians. The movement also coincided with the rise of the Home Rule League, founded by Tilak in 1916, which sought to mobilize mass support for self-rule. In the aftermath, the Swaraj Movement contributed to a growing sense of nationalism and unity among Indians, leading to increased political engagement and the eventual adoption of more radical methods of protest. The movement laid the groundwork for subsequent mass movements, including the Non-Cooperation Movement in the 1920s, and significantly influenced the trajectory of India's struggle for independence from British rule."
//   },
  'The Lucknow Pact (1916)': {
    image: '/textures/The Lucknow Pact (1916).jpg',
    position: [16, 5, -87],
    size: 1,
    description: "The Lucknow Pact was a political agreement reached in 1916 between the Indian National Congress and the Muslim League, aimed at strengthening Hindu-Muslim unity in the struggle for self-governance. The pact was negotiated by leaders such as Jinnah, Wazir Hasan, Gokhale, and Annie Besant, who sought to reverse the growing alienation between the two communities and present a united front against British rule. The agreement granted Muslims separate electorates and a share of seats in the provincial and central legislatures proportional to their population. In return, the Muslim League agreed to support the Congress' demand for self-rule. The Lucknow Pact marked a significant moment in the nationalist movement, as it brought together the two largest political parties in India and paved the way for increased cooperation in the years leading up to independence. However, the pact also alienated some Hindu groups, particularly in Punjab and Bengal, and encouraged the growth of communal organizations asserting the separate interests of their respective communities, laying the groundwork for future communal tensions and the eventual partition of India."
  },
  'Satyagraha (1917)': {
    image: '/textures/Satyagraha (1917).jpg',
    position: [44, 4, -106],
    size: 10,
    description: "In 1917, Mahatma Gandhi launched the Champaran Satyagraha in Bihar to address the grievances of indigo farmers who were being exploited by British planters under oppressive conditions. The farmers were forced to grow indigo on a portion of their land and sell it at unfair prices, leading to widespread poverty and distress. Gandhi traveled to Champaran after being invited by local leaders and conducted an investigation into the farmers' plight, mobilizing them to peacefully resist the injustices they faced. He organized a nonviolent protest, emphasizing the principles of Satyagraha, which advocated for truth and nonviolence as means of resistance. The British authorities initially resisted but ultimately conceded to the farmers' demands after realizing the extent of the unrest and Gandhi's influence. The aftermath of the Champaran Satyagraha marked a significant turning point in India's freedom struggle, as it established Gandhi as a prominent leader in the nationalist movement and demonstrated the effectiveness of nonviolent resistance. It also inspired further movements across India, reinforcing the idea of collective action against colonial oppression."
  },
  'The Kheda Satyagraha (1918)': {
    image: '/textures/The Kheda Satyagraha (1918).webp',
    position: [-117, 4.5, -36],
    size: 2,
    description: "The Kheda Satyagraha was a nonviolent civil disobedience movement launched by Mahatma Gandhi in 1918 in the Kheda district of Gujarat, in response to the British government's refusal to grant relief to farmers affected by crop failure and plague. Gandhi, along with other prominent leaders like Sardar Vallabhbhai Patel, organized the farmers to withhold payment of taxes until their demands were met. The Satyagraha, which emphasized nonviolent resistance, gained widespread support and eventually forced the British authorities to negotiate with the farmers, leading to a compromise that provided some relief and concessions. The Kheda Satyagraha was a significant milestone in Gandhi's development of the Satyagraha strategy and established him as a prominent leader in the Indian independence movement. It also demonstrated the power of collective nonviolent action in challenging colonial policies and highlighted the plight of the peasantry under British rule, inspiring further movements across India in the years leading up to independence."
  },
  'Rowlatt Act: Jallianwala Bagh Massacre': {
    image: '/textures/Jallianwala Bagh Massacre.jpg',
    position: [-73, 10, -165],
    size: 10,
    description: "Bhagha Jatin, an influential revolutionary leader, played a significant role in the resistance against British rule in India, particularly in the context of the Rowlatt Act of 1919, which allowed the British government to arrest and detain individuals without trial. The Act sparked widespread outrage among Indians, leading to protests and civil disobedience. One of the most tragic events linked to this period was the Jallianwala Bagh massacre on April 13, 1919, when British troops, under the command of General Dyer, opened fire on a peaceful gathering of thousands of Indians protesting against the Rowlatt Act in Amritsar. The brutal killing of hundreds of unarmed civilians shocked the nation and galvanized the Indian populace against British rule, leading to increased support for revolutionary activities, including those championed by Bhagha Jatin and his associates. The massacre not only intensified the demand for independence but also marked a turning point in the Indian freedom struggle, highlighting the urgent need for a unified response against colonial oppression and the call for self-rule."
  },
  'The Non-Cooperation Movement (1920-1922)': {
    image: '/textures/The Non-Cooperation Movement (1920-1922).jpg',
    position: [-85, 5, 113],
    size: 10,
    description: "The Non-Cooperation Movement was launched by Mahatma Gandhi in 1920 as a response to the Jallianwala Bagh massacre and the repressive Rowlatt Act, aiming to unite Indians in a nonviolent struggle against British colonial rule. Gandhi called upon Indians to withdraw their cooperation from the British government by boycotting British institutions, including schools, courts, and government offices, as well as refusing to pay taxes and promoting the use of swadeshi (Indian-made) goods. The movement gained widespread support across the country, mobilizing millions of Indians from various backgrounds and communities to participate in protests and demonstrations. However, the movement came to an abrupt end in 1922 after the violent incident at Chauri Chaura, where protesters killed police officers, leading Gandhi to call off the movement to maintain its nonviolent principles. The Non-Cooperation Movement marked a significant phase in the Indian independence struggle, demonstrating the power of mass mobilization and nonviolent resistance while also highlighting the complexities of Indian nationalism."
  },
  'Chauri Chaura Incident': {
    image: '/textures/Chauri Chaura Incident.jpg',
    position: [30, 12, -83],
    size: 10,
    description: "The Chauri Chaura incident, which occurred on February 4, 1922, had a profound impact on the trajectory of the Non-Cooperation Movement and the Indian independence struggle as a whole. During a protest against the arrest of local leaders, a group of protesters clashed with the police, leading to the deaths of several officers. In response, the protesters set fire to the police station, killing 22 policemen. Mahatma Gandhi, deeply disturbed by the violence, immediately called off the Non-Cooperation Movement, despite its widespread success in mobilizing millions of Indians against British rule. The incident highlighted the challenges of maintaining nonviolence in the face of provocation and the potential for unintended consequences when mass movements gain momentum. Gandhi's decision to suspend the movement was criticized by some as premature, but it underscored his commitment to the principles of nonviolence and his belief that the movement should not be tainted by bloodshed. The Chauri Chaura incident also demonstrated the British government's ability to exploit any deviation from nonviolence to discredit the nationalist movement. Despite the setback, the Non-Cooperation Movement had already left a lasting impact, strengthening the resolve of Indians to resist colonial rule and paving the way for future mass movements in the struggle for independence"
  },
  'Hindustan Republican Association (HRA)': {
    image: '/textures/Hindustan Republican Association (HRA).jpg',
    position: [8, 7, -90],
    size: 5,
    description: "The Hindustan Republican Association (HRA) was founded in the early 1920s by revolutionary leaders including Chandrashekhar Azad, Bhagat Singh, and others, aiming to overthrow British rule in India through armed struggle. The movement emerged as a response to the growing disillusionment with the moderate approaches of the Indian National Congress and the need for a more radical strategy to achieve independence. One of the most significant events associated with the HRA was the Kakori Conspiracy, which took place on August 9, 1925. During this event, HRA members, including Ram Prasad Bismil and Ashfaqulla Khan, executed a daring robbery of a train near Kakori, Uttar Pradesh, to obtain funds for their revolutionary activities. The robbery was intended to finance their struggle against British rule but resulted in the death of a guard, leading to a massive crackdown by British authorities. The subsequent investigation led to the arrest of many HRA members, including Bismil and Khan, who were later tried and executed. Chandrashekhar Azad, who was a key figure in the HRA, managed to evade capture and continued to lead revolutionary activities until his death in a police encounter in 1931. The Kakori incident and the actions of the HRA highlighted the growing radicalization of the Indian independence movement, inspiring a new generation of revolutionaries and drawing attention to the urgent need for independence from colonial rule. The bravery and sacrifice of these revolutionaries became emblematic of the struggle for freedom, further galvanizing public support for the cause."
  },
  'Simon Commission': {
    image: '/textures/Simon Commission.webp',
    position:  [-112, 5, 30],
    size: 5,
    description: "The Simon Commission, established in 1928 to review constitutional reforms in India, faced widespread backlash primarily because it did not include any Indian members, which was seen as a blatant disregard for Indian political aspirations. In response, the Indian National Congress and various political groups, including the Muslim League, organized protests and boycotts against the Commission, leading to significant unrest across the country. Concurrently, the Nehru Report of 1928, drafted by Motilal Nehru and aimed at outlining a constitutional framework for India, proposed self-governance and demanded full dominion status for India within the British Empire. However,it faced criticism from leaders reasoning no mention of swaraj and also it faced criticism from Muslim leaders who felt their interests were not adequately represented, leading to further divisions in the nationalist movement.This discontent culminated in significant demonstrations, including the famous slogan 'Simon Go Back,'' which reflected the unity of various political factions against British colonial policies. The backlash was further fueled by the ongoing disillusionment with British promises of reform, particularly after the failure of the Montagu-Chelmsford Reforms. The uproar surrounding the Simon Commission not only intensified the demand for Indian self-governance but also highlighted the urgent need for political representation, setting the stage for future movements and negotiations in the struggle for independence."
  },
  'Bhagat Singh': {
    image: '/textures/Bhagat Singh.jpg',
    position: [-100, 10, -165],
    size: 10,
    description: "Bhagat Singh, a prominent revolutionary figure in the Indian independence movement, gained national attention in 1928 for his involvement in the killing of a British police officer , John Saunders, in retaliation for the death of nationalist leader Lala Lajpat Rai. This incident, known as the Lahore Conspiracy Case, led to Bhagat Singh's arrest and subsequent trial, during which he and his comrades boldly declared their commitment to complete independence (Purna Swaraj) from British rule. Bhagat Singh's defiant stance and his willingness to sacrifice his life at the age of 23 for the cause of freedom inspired a new generation of revolutionaries and galvanized public support for the independence movement. The impact of Bhagat Singh's actions was profound, as it shifted the focus of the nationalist struggle from dominion status to complete independence and challenged the British government's authority. The slogan 'Inquilab Zindabad' (Long Live the Revolution), coined by Bhagat Singh and his associates, became a rallying cry for the revolutionary movement, capturing the spirit of resistance and the desire for a complete break from colonial rule. Bhagat Singh's execution in 1931, along with his comrades Sukhdev and Rajguru, further cemented his status as a martyr and a symbol of the sacrifices made in the fight for India's independence."
  },
  'Dandi March': {
    image: '/textures/Dandi March.jpg',
    position: [-123, 5, -40],
    size: 10,
    description: "The Dandi March, also known as the Salt March, was a pivotal event in the Indian independence movement led by Mahatma Gandhi in 1930. The march commenced on March 12 and lasted for 24 days, covering approximately 240 miles from Sabarmati Ashram to the coastal village of Dandi in Gujarat. Gandhi organized the march to protest the British salt tax, which imposed a monopoly on salt production and sales, symbolizing the broader injustices of British colonial rule. By marching to the sea to make his own salt, Gandhi aimed to challenge the legitimacy of British authority and inspire mass civil disobedience across India. The impact of the Dandi March was profound; it galvanized millions of Indians to join the struggle for independence, leading to widespread protests and acts of defiance against British laws. The march also attracted international attention, highlighting the Indian struggle for freedom and showcasing Gandhi's philosophy of nonviolent resistance. The Dandi March marked a significant turning point in the independence movement, as it united diverse sections of Indian society and demonstrated the power of collective action, ultimately contributing to the growing demand for complete independence from British rule.Moreover, the Dandi March garnered international attention, highlighting the injustices of British colonial rule and portraying Gandhi as a prominent leader advocating for nonviolent resistance. It also led to a series of subsequent movements, including the Civil Disobedience Movement, which further intensified the demand for self-rule. The march marked a shift in the Indian independence struggle, emphasizing mass participation and civil disobedience as effective strategies against colonial oppression. Ultimately, the Dandi March and its aftermath laid the groundwork for future negotiations ."
  },
  'Subhas Chandra Bose': {
    image: '/textures/Subhas Chandra Bose.jpg',
    position: [70, 5, 0],
    size: 10,
    description: "The All India Forward Bloc was a political party founded by Subhas Chandra Bose in 1939 as a faction within the Indian National Congress. Bose, a prominent leader in the independence movement, had been elected as the Congress president in 1938 but faced opposition from the more conservative elements within the party. Disagreeing with the Congress' policy of cooperation with the British during World War II, Bose resigned from the party and established the Forward Bloc to pursue a more aggressive and independent path towards India's freedom.The Forward Bloc aimed to unite various left-wing and revolutionary groups within the nationalist movement, advocating for complete independence from British rule and a socialist economic program. Bose's charismatic leadership and his call for immediate action resonated with many Indians, particularly the youth, who were growing impatient with the Congress' cautious approach.The aftermath of the Forward Bloc's formation was significant. Bose's activities were closely monitored by the British authorities, and he was eventually placed under house arrest in 1940. However, he managed to escape in 1941 and traveled to Germany and Japan, seeking their support for an armed struggle against the British. Bose's disappearance and the subsequent crackdown on the Forward Bloc by the British did not diminish the party's influence. It continued to operate as a separate entity within the broader independence movement, advocating for a more radical and militant approach to achieving freedom."
  },
  'August Offer': {
    image: '/textures/August Offer.jpeg',
    position: [-35, 5, 5],
    size: 5,
    description: "The August Offer was a proposal made by the British government in 1940 in response to the growing demands for Indian self-governance and the increasing unrest in the country during World War II. The offer included promises of constitutional reforms and the establishment of a representative body to discuss India's future after the war, as well as a vague commitment to consider the demand for greater self-government. However, it did not provide any concrete plans for immediate self-rule or address the concerns of key Indian leaders, particularly regarding the representation of different communities and the demand for complete independence.The Indian National Congress, led by prominent leaders like Mahatma Gandhi and Jawaharlal Nehru, rejected the August Offer primarily because it fell short of their demand for full independence and did not adequately address the aspirations of the Indian people. The Congress viewed the offer as an attempt to placate Indian leaders while maintaining British control over India. Additionally, the offer was made during a time when the Congress was demanding a clear commitment to self-rule, and the vague nature of the proposals was seen as insufficient to meet the urgent needs of the Indian populace. The rejection of the August Offer marked a significant moment in the Indian independence movement, as it underscored the growing impatience with British colonial rule and the determination of Indian leaders to secure complete independence rather than half-measures."
  },
  'Quit India Movement': {
    image: '/textures/Quit India Movement.jpg',
    position: [-118, 8, 20],
    size: 10,
    description: "The Quit India Movement, launched on August 8, 1942, was a mass protest against British rule in India spearheaded by the Indian National Congress, led by Mahatma Gandhi, who called for an immediate end to British colonialism. The movement was initiated in response to the failure of the Cripps Mission, which sought to negotiate constitutional reforms but did not offer any significant concessions for Indian independence. Gandhi's call for 'Do or Die' galvanized millions of Indians, leading to widespread demonstrations, strikes, and acts of civil disobedience across the country. The British authorities responded with severe repression, arresting key leaders, including Gandhi and Nehru, and deploying military force to suppress the protests. Despite the brutal crackdown, the Quit India Movement marked a turning point in the Indian independence struggle, as it demonstrated the widespread desire for freedom and the readiness of ordinary Indians to participate in the fight against colonial rule. The movement also intensified national consciousness and unity among diverse groups, laying the groundwork for the eventual independence of India in 1947."
  },
  'Azad Hind Fauj': {
    image: '/textures/Azad Hind Fauj.jpeg',
    position: [220, 140, 300],
    size: 130,
    description: "The Azad Hind Fauj (Indian National Army) was formed in 1942 by Rash Behari Bose with the support of Japan, with the aim of liberating India from British rule. In 1943, Subhas Chandra Bose took over the leadership of the INA and forged a close alliance with Japan, which provided resources and training for the army. As part of this collaboration, Japan handed over control of the Andaman and Nicobar Islands to the Azad Hind government led by Bose, who established an administration there. Bose's famous slogan 'Tum mujhe khoon do, main tumhe azadi dunga' (Give me blood, and I shall give you freedom) became a rallying cry for the INA, inspiring many Indians to join the fight for independence. The INA's alliance with Japan, though controversial, demonstrated the lengths to which some Indian revolutionaries were willing to go to achieve freedom from British colonial rule."
  },
  'Battle of Imphal': {
    image: '/textures/Battle of Imphal.jpg',
    position: [175, 5, -70],
    size: 10,
    description: "The Battle of Imphal, fought between March and July 1944, was a significant military engagement during World War II, involving the Japanese forces and the Indian National Army (INA) against the British Indian Army in the Imphal region of Manipur, India. The Japanese aimed to capture Imphal and subsequently advance into India, but the British forces, well-prepared and strategically positioned, mounted a strong defense. The battle was characterized by intense fighting and significant casualties on both sides, with the British ultimately managing to repel the Japanese offensive.One of the notable events during this campaign was the INA's victory at Moirang on April 14, 1944, where they successfully captured the town, marking the first time an Indian army had taken Indian territory from the British. This victory was symbolic for the INA and Subhas Chandra Bose, as it represented a significant step towards their goal of Indian independence. However, despite the initial successes, the overall campaign turned disastrous when the Japanese suffered from Hiroshima attack."
  },
  'Independence ': {
    image: '/textures/independence.jpg',
    position: [0, 250, 150],
    size: 20,
    description: "In the early 1940s, India was grappling with extreme poverty and widespread despair, with little left for the British to exploit. The international community, increasingly critical of colonialism, began pressuring Britain to grant India independence. The Quit India Movement of 1942, fueled by Indian anger and determination, saw mass protests and civil disobedience across the nation. Concurrently, in the UK, the Labour Party's election campaign emphasized decolonization, promising to grant India independence. This political shift in Britain, combined with mounting international and domestic pressures, set the stage for India's eventual independence in August 15 1947.The intense struggle for independence ultimately led to the birth of two nations: India and Pakistan. As the British withdrew, the subcontinent was divided along religious lines, leading to the creation of Pakistan as a separate Muslim-majority state, while India remained a predominantly Hindu nation. This partition, though marking the end of colonial rule, also sparked massive upheaval, with millions displaced and deep-seated tensions that would shape the region's future. As we explore the aftermath of independence, we'll delve into the profound impact of this split and the challenges both nations faced in their formative years."
  }
};

const Event = React.forwardRef(({ name }, ref) => {
    const { image, position, size } = eventData[name];
    const texture = useTexture(`https://kshira-sagara.s3.ap-south-1.amazonaws.com/landing-page/social${image}`);
    const meshRef = useRef();
    const textRef = useRef();
  
    useEffect(() => {
      if (textRef.current) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = 256;
        canvas.height = 64;
        context.font = 'Bold 15px Arial';
        context.fillStyle = 'red';
        context.textAlign = 'center';
        context.fillText(name, 128, 32);
        const textTexture = new THREE.CanvasTexture(canvas);
        textRef.current.material.map = textTexture;
        textRef.current.material.needsUpdate = true;
      }
    }, [name]);
  
    useFrame(({ camera }) => {
      if (meshRef.current) {
        meshRef.current.quaternion.copy(camera.quaternion);
        meshRef.current.rotation.z += 0.005;
      }
      if (textRef.current) {
        textRef.current.quaternion.copy(camera.quaternion);
      }
    });
  
    return (
      <group position={position}>
        <mesh ref={meshRef}>
          <planeGeometry args={[size, size]} />
          <meshBasicMaterial map={texture} transparent opacity={0.8} side={THREE.DoubleSide} />
        </mesh>
        <mesh ref={textRef} position={[0, size / 2 + 0.5, 0]}>
          <planeGeometry args={[size, size / 4]} />
          <meshBasicMaterial transparent depthTest={false} />
        </mesh>
      </group>
    );
  });
  
  export { Event, eventData };