export const tlmData = [
    {
      "product": "beaker_50ml",
      "image_path": "product_images/beaker_50ml_2.jpg",
      "product_code": "101SCB1"
    },
    {
      "product": "beaker_100ml",
      "image_path": "product_images/beaker_100ml_2.jpg",
      "product_code": "101SCB2"
    },
    {
        "product": "ring_magnets",
        "image_path": "product_imagesS113/ring_magnets_4.jpg",
        "product_code": "113SCR1"
      },
      {
        "product": "magnetic_compass",
        "image_path": "product_imagesS113/magnetic_compass_3.jpg",
        "product_code": "113SCM1"
      },
      {
        "product": "solenoid_coils_set",
        "image_path": "product_imagesS113/solenoid_coils_set_4.jpg",
        "product_code": "113SCS1"
      },
      {
        "product": "electromagnet_kit",
        "image_path": "product_imagesS113/electromagnet_kit_3.jpg",
        "product_code": "113SCE1"
      },
    {
      "product": "test_tube",
      "image_path": "product_images/test_tube_5.jpg",
      "product_code": "101SCT1"
    },
    {
      "product": "test_tube_rack",
      "image_path": "product_images/test_tube_rack_2.jpg",
      "product_code": "101SCR1"
    },
    {
      "product": "test_tube_rack",
      "image_path": "product_images/test_tube_rack_3.jpg",
      "product_code": "101SCR1"
    },
    {
      "product": "test_tube_rack",
      "image_path": "product_images/test_tube_rack_4.jpg",
      "product_code": "101SCR1"
    },
    {
      "product": "test_tube_rack",
      "image_path": "product_images/test_tube_rack_5.jpg",
      "product_code": "101SCR1"
    },
    {
      "product": "watch_glass",
      "image_path": "product_images/watch_glass_2.jpg",
      "product_code": "101SCW1"
    },
    {
      "product": "watch_glass",
      "image_path": "product_images/watch_glass_5.jpg",
      "product_code": "101SCW1"
    },
    {
      "product": "conical_flask",
      "image_path": "product_images/conical_flask_3.jpg",
      "product_code": "101SCF1"
    },
    {
      "product": "round_bottom_flask",
      "image_path": "product_images/round_bottom_flask_4.jpg",
      "product_code": "101SCF2"
    },
    {
      "product": "graduated_cylinder_10ml",
      "image_path": "product_images/graduated_cylinder_10ml_4.jpg",
      "product_code": "101SCG1"
    },
    {
      "product": "graduated_cylinder_50ml",
      "image_path": "product_images/graduated_cylinder_50ml_3.jpg",
      "product_code": "101SCG2"
    },
    {
      "product": "graduated_cylinder_50ml",
      "image_path": "product_images/graduated_cylinder_50ml_5.jpg",
      "product_code": "101SCG2"
    },
    {
      "product": "graduated_cylinder_100ml",
      "image_path": "product_images/graduated_cylinder_100ml_4.jpg",
      "product_code": "101SCG3"
    },
    {
      "product": "pipette",
      "image_path": "product_images/pipette_2.jpg",
      "product_code": "101SCP1"
    },
    {
      "product": "burette",
      "image_path": "product_images/burette_4.jpg",
      "product_code": "101SCB5"
    },
    {
      "product": "petri_dish",
      "image_path": "product_images/petri_dish_2.jpg",
      "product_code": "101SCP2"
    },
    {
        "product": "beaker_100ml",
        "image_path": "product_images/beaker_100ml_5.jpg",
        "product_code": "101SCB2"
      },
      {
        "product": "beaker_250ml",
        "image_path": "product_images/beaker_250ml_2.jpg",
        "product_code": "101SCB3"
      },
      {
        "product": "beaker_250ml",
        "image_path": "product_images/beaker_250ml_3.jpg",
        "product_code": "101SCB3"
      },
      {
        "product": "beaker_500ml",
        "image_path": "product_images/beaker_500ml_2.jpg",
        "product_code": "101SCB4"
      },
      {
        "product": "test_tube",
        "image_path": "product_images/test_tube_3.jpg",
        "product_code": "101SCT1"
      },
      
    {
      "product": "bunsen_burner",
      "image_path": "product_images/bunsen_burner_3.jpg",
      "product_code": "101SCB6"
    },
    {
      "product": "spirit_lamp",
      "image_path": "product_images/spirit_lamp_4.jpg",
      "product_code": "101SCL1"
    },
    {
      "product": "wire_gauze",
      "image_path": "product_images/wire_gauze_2.jpg",
      "product_code": "101SCW2"
    },
    {
      "product": "crucible",
      "image_path": "product_images/crucible_3.jpg",
      "product_code": "101SCC1"
    },
    {
      "product": "crucible_tongs",
      "image_path": "product_images/crucible_tongs_3.jpg",
      "product_code": "101SCT3"
    },
    {
      "product": "safety_goggles",
      "image_path": "product_images/safety_goggles_4.jpg",
      "product_code": "101SCG4"
    },
    {
      "product": "rubber_gloves",
      "image_path": "product_images/rubber_gloves_2.jpg",
      "product_code": "101SCG5"
    },
    {
      "product": "first_aid_kit",
      "image_path": "product_images/first_aid_kit_3.jpg",
      "product_code": "101SCF4"
    },
    {
      "product": "electronic_balance",
      "image_path": "product_images/electronic_balance_4.jpg",
      "product_code": "101SCB7"
    },
    {
      "product": "ph_meter",
      "image_path": "product_images/ph_meter_5.jpg",
      "product_code": "101SCP3"
    },
    {
      "product": "thermometer",
      "image_path": "product_images/thermometer_2.jpg",
      "product_code": "101SCT4"
    },
    {
      "product": "thermometer",
      "image_path": "product_images/thermometer_4.jpg",
      "product_code": "101SCT4"
    },
    {
      "product": "stopwatch",
      "image_path": "product_images/stopwatch_2.jpg",
      "product_code": "101SCS1"
    },
    {
      "product": "stopwatch",
      "image_path": "product_images/stopwatch_3.jpg",
      "product_code": "101SCS1"
    },
    {
      "product": "sodium_hydroxide",
      "image_path": "product_images/sodium_hydroxide_2.jpg",
      "product_code": "101SCN2"
    },
    {
      "product": "sodium_hydroxide",
      "image_path": "product_images/sodium_hydroxide_5.jpg",
      "product_code": "101SCN2"
    },
    {
      "product": "potassium_hydroxide",
      "image_path": "product_images/potassium_hydroxide_4.jpg",
      "product_code": "101SCP4"
    },
    {
      "product": "calcium_hydroxide",
      "image_path": "product_images/calcium_hydroxide_2.jpg",
      "product_code": "101SCC2"
    },
    {
      "product": "calcium_hydroxide",
      "image_path": "product_images/calcium_hydroxide_5.jpg",
      "product_code": "101SCC2"
    },
    {
      "product": "magnesium_ribbon",
      "image_path": "product_images/magnesium_ribbon_2.jpg",
      "product_code": "101SCM1"
    },
    {
      "product": "copper_wire",
      "image_path": "product_images/copper_wire_2.jpg",
      "product_code": "101SCC3"
    },
    {
      "product": "iron_filings",
      "image_path": "product_images/iron_filings_3.jpg",
      "product_code": "101SCF5"
    },
    {
      "product": "zinc_granules",
      "image_path": "product_images/zinc_granules_2.jpg",
      "product_code": "101SCZ1"
    },
    {
      "product": "aluminium_foil",
      "image_path": "product_images/aluminium_foil_4.jpg",
      "product_code": "101SCA2"
    },
    {
      "product": "sodium_carbonate",
      "image_path": "product_images/sodium_carbonate_2.jpg",
      "product_code": "101SCN3"
    },
    {
      "product": "calcium_carbonate",
      "image_path": "product_images/calcium_carbonate_4.jpg",
      "product_code": "101SCC4"
    },
    {
      "product": "copper_sulphate",
      "image_path": "product_images/copper_sulphate_2.jpg",
      "product_code": "101SCC5"
    },
    {
      "product": "copper_sulphate",
      "image_path": "product_images/copper_sulphate_5.jpg",
      "product_code": "101SCC5"
    },
    {
      "product": "potassium_permanganate",
      "image_path": "product_images/potassium_permanganate_2.jpg",
      "product_code": "101SCP5"
    },
    {
      "product": "potassium_permanganate",
      "image_path": "product_images/potassium_permanganate_5.jpg",
      "product_code": "101SCP5"
    },
    {
      "product": "barium_chloride",
      "image_path": "product_images/barium_chloride_4.jpg",
      "product_code": "101SCB8"
    },
    {
      "product": "lead_nitrate",
      "image_path": "product_images/lead_nitrate_3.jpg",
      "product_code": "101SCL3"
    },
    {
      "product": "litmus_paper_red",
      "image_path": "product_images/litmus_paper_red_2.jpg",
      "product_code": "101SCL4"
    },
    {
      "product": "litmus_paper_blue",
      "image_path": "product_images/litmus_paper_blue_2.jpg",
      "product_code": "101SCL5"
    },
    {
      "product": "methyl_orange",
      "image_path": "product_images/methyl_orange_4.jpg",
      "product_code": "101SCM2"
    },
    {
      "product": "dropper",
      "image_path": "product_images/dropper_5.jpg",
      "product_code": "101SCD1"
    },
    {
      "product": "forceps",
      "image_path": "product_images/forceps_3.jpg",
      "product_code": "101SCF7"
    },
    {
      "product": "stirring_rod",
      "image_path": "product_images/stirring_rod_3.jpg",
      "product_code": "101SCS5"
    },
    {
      "product": "stirring_rod",
      "image_path": "product_images/stirring_rod_4.jpg",
      "product_code": "101SCS5"
    },
    {
      "product": "wash_bottle",
      "image_path": "product_images/wash_bottle_2.jpg",
      "product_code": "101SCW3"
    },
    {
      "product": "mortar_and_pestle",
      "image_path": "product_images/mortar_and_pestle_3.jpg",
      "product_code": "101SCM3"
    },
    {
      "product": "filter_paper",
      "image_path": "product_images/filter_paper_3.jpg",
      "product_code": "101SCF9"
    },
    {
      "product": "funnel",
      "image_path": "product_images/funnel_2.jpg",
      "product_code": "101SCF10"
    },
    {
      "product": "funnel",
      "image_path": "product_images/funnel_5.jpg",
      "product_code": "101SCF10"
    },
    {
      "product": "rubber_stopper",
      "image_path": "product_images/rubber_stopper_2.jpg",
      "product_code": "101SCR2"
    },
    {
      "product": "rubber_stopper",
      "image_path": "product_images/rubber_stopper_3.jpg",
      "product_code": "101SCR2"
    },
    {
      "product": "clamp_stand",
      "image_path": "product_images/clamp_stand_2.jpg",
      "product_code": "101SCC6"
    },
    {
      "product": "clamp_stand",
      "image_path": "product_images/clamp_stand_3.jpg",
      "product_code": "101SCC6"
    },
    {
      "product": "clamp_stand",
      "image_path": "product_images/clamp_stand_5.jpg",
      "product_code": "101SCC6"
    },
    {
      "product": "buret_clamp",
      "image_path": "product_images/buret_clamp_3.jpg",
      "product_code": "101SCB10"
    },
    {
      "product": "test_tube_holder",
      "image_path": "product_images/test_tube_holder_2.jpg",
      "product_code": "101SCT6"
    },
    {
      "product": "test_tube_holder",
      "image_path": "product_images/test_tube_holder_5.jpg",
      "product_code": "101SCT6"
    },
    {
      "product": "evaporating_dish",
      "image_path": "product_images/evaporating_dish_4.jpg",
      "product_code": "101SCE2"
    },
    {
      "product": "lab_notebook",
      "image_path": "product_images/lab_notebook_5.jpg",
      "product_code": "101SCN5"
    },
    {
      "product": "marker",
      "image_path": "product_images/marker_2.jpg",
      "product_code": "101SCM4"
    },
    {
      "product": "pencil",
      "image_path": "product_images/pencil_3.jpg",
      "product_code": "101SCP7"
    },
    {
      "product": "calculator",
      "image_path": "product_images/calculator_4.jpg",
      "product_code": "101SCC8"
    },
    {
      "product": "periodic_table_chart",
      "image_path": "product_images/periodic_table_chart_5.jpg",
      "product_code": "101SCP8"
    },
    {
        "product": "osmometer",
        "image_path": "product_imagesS102/osmometer_3.jpg",
        "product_code": "102SCO2"
      },
      {
        "product": "red_cabbage",
        "image_path": "product_imagesS102/red_cabbage_2.jpg",
        "product_code": "102SCR2"
      },
      {
        "product": "turmeric_powder",
        "image_path": "product_imagesS102/turmeric_powder_3.jpg",
        "product_code": "102SCT6"
      },
      {
        "product": "detergent_powder",
        "image_path": "product_imagesS102/detergent_powder_2.jpg",
        "product_code": "102SCD3"
      },
      {
        "product": "neutralizing_spill_kit",
        "image_path": "product_imagesS102/neutralizing_spill_kit_2.jpg",
        "product_code": "102SCN5"
      },
      {
        "product": "acid_storage_cabinet",
        "image_path": "product_imagesS102/acid_storage_cabinet_3.jpg",
        "product_code": "102SCA12"
      },
      {
        "product": "base_storage_cabinet",
        "image_path": "product_imagesS102/base_storage_cabinet_3.jpg",
        "product_code": "102SCB9"
      },
      {
        "product": "molecular_model_set_for_acids_and_bases",
        "image_path": "product_imagesS102/molecular_model_set_for_acids_and_bases_3.jpg",
        "product_code": "102SCM6"
      },
      {
        "product": "molecular_model_set_for_acids_and_bases",
        "image_path": "product_imagesS102/molecular_model_set_for_acids_and_bases_4.jpg",
        "product_code": "102SCM6"
      },
      {
        "product": "data_logger_with_pH_probe",
        "image_path": "product_imagesS102/data_logger_with_pH_probe_2.jpg",
        "product_code": "102SCD4"
      },
      {
        "product": "handheld_digital_pH_meter",
        "image_path": "product_imagesS102/handheld_digital_pH_meter_3.jpg",
        "product_code": "102SCH3"
      },
      {
        "product": "pH_electrode_storage_tube",
        "image_path": "product_imagesS102/pH_electrode_storage_tube_2.jpg",
        "product_code": "102SCP12"
      },
      {
        "product": "specific_gravity_bottle",
        "image_path": "product_imagesS102/specific_gravity_bottle_2.jpg",
        "product_code": "102SCS8"
      },
      {
        "product": "specific_gravity_bottle",
        "image_path": "product_imagesS102/specific_gravity_bottle_5.jpg",
        "product_code": "102SCS8"
      },
      {
        "product": "face_shield",
        "image_path": "product_imagesS102/face_shield_3.jpg",
        "product_code": "102SCF3"
      },
      {
        "product": "face_shield",
        "image_path": "product_imagesS102/face_shield_5.jpg",
        "product_code": "102SCF3"
      },
      {
        "product": "litmus_paper_booklet",
        "image_path": "product_imagesS102/litmus_paper_booklet_5.jpg",
        "product_code": "102SCL5"
      },
      {
        "product": "solubility_chart",
        "image_path": "product_imagesS102/solubility_chart_3.jpg",
        "product_code": "102SCS9"
      },
      {
        "product": "periodic_table_with_acid_base_properties",
        "image_path": "product_imagesS102/periodic_table_with_acid_base_properties_3.jpg",
        "product_code": "102SCP14"
      },
      
        {
          "product": "metal_activity_series_kit",
          "image_path": "product_imagesS103/metal_activity_series_kit_5.jpg",
          "product_code": "103SCM1"
        },
        {
          "product": "alloy_sample_set",
          "image_path": "product_imagesS103/alloy_sample_set_2.jpg",
          "product_code": "103SCA1"
        },
        {
          "product": "alloy_sample_set",
          "image_path": "product_imagesS103/alloy_sample_set_5.jpg",
          "product_code": "103SCA1"
        },
        {
          "product": "mineral_identification_kit",
          "image_path": "product_imagesS103/mineral_identification_kit_2.jpg",
          "product_code": "103SCM2"
        },
        {
          "product": "mineral_identification_kit",
          "image_path": "product_imagesS103/mineral_identification_kit_3.jpg",
          "product_code": "103SCM2"
        },
        {
          "product": "electroplating_kit",
          "image_path": "product_imagesS103/electroplating_kit_5.jpg",
          "product_code": "103SCE3"
        },
        {
          "product": "iron_sample",
          "image_path": "product_imagesS103/iron_sample_5.jpg",
          "product_code": "103SCI1"
        },
        {
          "product": "copper_sample",
          "image_path": "product_imagesS103/copper_sample_2.jpg",
          "product_code": "103SCC1"
        },
        {
          "product": "aluminum_sample",
          "image_path": "product_imagesS103/aluminum_sample_3.jpg",
          "product_code": "103SCA2"
        },
        {
          "product": "zinc_sample",
          "image_path": "product_imagesS103/zinc_sample_2.jpg",
          "product_code": "103SCZ1"
        },
        {
          "product": "zinc_sample",
          "image_path": "product_imagesS103/zinc_sample_4.jpg",
          "product_code": "103SCZ1"
        },
        {
          "product": "sodium_metal_in_kerosene",
          "image_path": "product_imagesS103/sodium_metal_in_kerosene_5.jpg",
          "product_code": "103SCN1"
        },
        {
          "product": "potassium_metal_in_kerosene",
          "image_path": "product_imagesS103/potassium_metal_in_kerosene_5.jpg",
          "product_code": "103SCP1"
        },
        {
          "product": "brass_sample",
          "image_path": "product_imagesS103/brass_sample_4.jpg",
          "product_code": "103SCB2"
        },
        {
          "product": "sulphur_powder",
          "image_path": "product_imagesS103/sulphur_powder_3.jpg",
          "product_code": "103SCS3"
        },
        {
          "product": "carbon_graphite_rods",
          "image_path": "product_imagesS103/carbon_graphite_rods_5.jpg",
          "product_code": "103SCC2"
        },
        {
          "product": "iodine_crystals",
          "image_path": "product_imagesS103/iodine_crystals_2.jpg",
          "product_code": "103SCI2"
        },
        {
          "product": "hematite_sample",
          "image_path": "product_imagesS103/hematite_sample_2.jpg",
          "product_code": "103SCH1"
        },
        {
          "product": "bauxite_sample",
          "image_path": "product_imagesS103/bauxite_sample_2.jpg",
          "product_code": "103SCB4"
        },
        {
          "product": "galena_sample",
          "image_path": "product_imagesS103/galena_sample_2.jpg",
          "product_code": "103SCG1"
        },
        {
          "product": "magnetite_sample",
          "image_path": "product_imagesS103/magnetite_sample_2.jpg",
          "product_code": "103SCM6"
        },
        {
          "product": "magnetite_sample",
          "image_path": "product_imagesS103/magnetite_sample_4.jpg",
          "product_code": "103SCM6"
        },
        {
          "product": "chalcopyrite_sample",
          "image_path": "product_imagesS103/chalcopyrite_sample_3.jpg",
          "product_code": "103SCC3"
        },
        {
          "product": "sphalerite_sample",
          "image_path": "product_imagesS103/sphalerite_sample_2.jpg",
          "product_code": "103SCS5"
        },
        {
          "product": "sphalerite_sample",
          "image_path": "product_imagesS103/sphalerite_sample_3.jpg",
          "product_code": "103SCS5"
        },
        {
          "product": "potassium_permanganate",
          "image_path": "product_imagesS103/potassium_permanganate_2.jpg",
          "product_code": "103SCP3"
        },
        {
          "product": "potassium_permanganate",
          "image_path": "product_imagesS103/potassium_permanganate_5.jpg",
          "product_code": "103SCP3"
        },
        {
          "product": "deflagrating_spoon",
          "image_path": "product_imagesS103/deflagrating_spoon_3.jpg",
          "product_code": "103SCD1"
        },
        {
          "product": "metal_file_set",
          "image_path": "product_imagesS103/metal_file_set_2.jpg",
          "product_code": "103SCM7"
        },
        {
          "product": "metal_file_set",
          "image_path": "product_imagesS103/metal_file_set_3.jpg",
          "product_code": "103SCM7"
        },
        {
          "product": "hammers_set",
          "image_path": "product_imagesS103/hammers_set_5.jpg",
          "product_code": "103SCH3"
        },
        {
          "product": "soldering_iron",
          "image_path": "product_imagesS103/soldering_iron_4.jpg",
          "product_code": "103SCS7"
        },
        {
          "product": "wire_cutters",
          "image_path": "product_imagesS103/wire_cutters_2.jpg",
          "product_code": "103SCW1"
        },
        {
          "product": "heat_resistant_gloves",
          "image_path": "product_imagesS103/heat_resistant_gloves_4.jpg",
          "product_code": "103SCH4"
        },
        {
          "product": "multimeter",
          "image_path": "product_imagesS103/multimeter_3.jpg",
          "product_code": "103SCM9"
        },
        {
          "product": "multimeter",
          "image_path": "product_imagesS103/multimeter_4.jpg",
          "product_code": "103SCM9"
        },
        {
          "product": "density_determination_kit",
          "image_path": "product_imagesS103/density_determination_kit_5.jpg",
          "product_code": "103SCD2"
        },
        {
          "product": "vacuum_tubes_for_metal_vapor_lamps",
          "image_path": "product_imagesS103/vacuum_tubes_for_metal_vapor_lamps_5.jpg",
          "product_code": "103SCV1"
        },
        {
          "product": "x-ray_diffraction_simulation_kit",
          "image_path": "product_imagesS103/x-ray_diffraction_simulation_kit_5.jpg",
          "product_code": "103SCX1"
        },
        {
          "product": "metallurgical_microscope",
          "image_path": "product_imagesS103/metallurgical_microscope_5.jpg",
          "product_code": "103SCM13"
        },
        {
          "product": "famous_chemists_in_inorganic_chemistry_poster",
          "image_path": "product_imagesS103/famous_chemists_in_inorganic_chemistry_poster_5.jpg",
          "product_code": "103SCF4"
        },
        {
          "product": "geiger_counter",
          "image_path": "product_imagesS103/geiger_counter_2.jpg",
          "product_code": "103SCG2"
        },
        {
          "product": "geiger_counter",
          "image_path": "product_imagesS103/geiger_counter_3.jpg",
          "product_code": "103SCG2"
        }
      ,

      
        {
          "product": "carbon_fiber_sample",
          "image_path": "product_imagesS104/carbon_fiber_sample_2.jpg",
          "product_code": "104SCC2"
        },
        {
          "product": "carbon_nanotube_model",
          "image_path": "product_imagesS104/carbon_nanotube_model_5.jpg",
          "product_code": "104SCC3"
        },
        {
          "product": "glucose_powder",
          "image_path": "product_imagesS104/glucose_powder_3.jpg",
          "product_code": "104SCG3"
        },
        {
          "product": "sucrose_crystals",
          "image_path": "product_imagesS104/sucrose_crystals_3.jpg",
          "product_code": "104SCS1"
        },
        {
          "product": "starch_powder",
          "image_path": "product_imagesS104/starch_powder_5.jpg",
          "product_code": "104SCS2"
        },
        {
          "product": "sodium_hydroxide_pellets",
          "image_path": "product_imagesS104/sodium_hydroxide_pellets_2.jpg",
          "product_code": "101SR101SCN2"
        },
        {
          "product": "potassium_permanganate_crystals",
          "image_path": "product_imagesS104/potassium_permanganate_crystals_2.jpg",
          "product_code": "104SCP2"
        },
        {
          "product": "bromine_water",
          "image_path": "product_imagesS104/bromine_water_3.jpg",
          "product_code": "104SCB2"
        },
        {
          "product": "ph_paper",
          "image_path": "product_imagesS104/ph_paper_4.jpg",
          "product_code": "101SR101SCN4"
        },
        {
          "product": "test_tube_holder",
          "image_path": "product_imagesS104/test_tube_holder_4.jpg",
          "product_code": "101SR101SCT6"
        },
        {
          "product": "pipettes_set",
          "image_path": "product_imagesS104/pipettes_set_4.jpg",
          "product_code": "104SCP3"
        },
        {
          "product": "burettes_set",
          "image_path": "product_imagesS104/burettes_set_3.jpg",
          "product_code": "104SCB5"
        },
        {
          "product": "burettes_set",
          "image_path": "product_imagesS104/burettes_set_5.jpg",
          "product_code": "104SCB5"
        },
        {
          "product": "separatory_funnel",
          "image_path": "product_imagesS104/separatory_funnel_2.jpg",
          "product_code": "104SCS6"
        },
        {
          "product": "buchner_funnel",
          "image_path": "product_imagesS104/buchner_funnel_5.jpg",
          "product_code": "104SCB6"
        },
        {
          "product": "leibig_condenser",
          "image_path": "product_imagesS104/leibig_condenser_5.jpg",
          "product_code": "104SCL2"
        },
        {
          "product": "solvent_resistant_gloves",
          "image_path": "product_imagesS104/solvent_resistant_gloves_3.jpg",
          "product_code": "104SCS8"
        },
        {
          "product": "infrared_thermometer",
          "image_path": "product_imagesS104/infrared_thermometer_2.jpg",
          "product_code": "104SCI2"
        },
        {
          "product": "digital_pH_meter",
          "image_path": "product_imagesS104/digital_pH_meter_4.jpg",
          "product_code": "104SCP4"
        },
        {
          "product": "famous_organic_chemists_poster",
          "image_path": "product_imagesS104/famous_organic_chemists_poster_2.jpg",
          "product_code": "104SCF8"
        },
        {
          "product": "polymer_samples_kit",
          "image_path": "product_imagesS104/polymer_samples_kit_5.jpg",
          "product_code": "104SCP6"
        },
        {
          "product": "carbon_dating_simulation_kit",
          "image_path": "product_imagesS104/carbon_dating_simulation_kit_3.jpg",
          "product_code": "104SCC8"
        },
        {
          "product": "diamond_structure_crystal_model",
          "image_path": "product_imagesS104/diamond_structure_crystal_model_3.jpg",
          "product_code": "104SCD3"
        }
        ,

        
            {
              "product": "horseshoe_magnets",
              "image_path": "product_imagesS113/horseshoe_magnets_2.jpg",
              "product_code": "113SCH1"
            },
            {
              "product": "ring_magnets",
              "image_path": "product_imagesS113/ring_magnets_3.jpg",
              "product_code": "113SCR1"
            },
            
            {
              "product": "relay_switch",
              "image_path": "product_imagesS113/relay_switch_2.jpg",
              "product_code": "113SCR2"
            },
            {
              "product": "relay_switch",
              "image_path": "product_imagesS113/relay_switch_5.jpg",
              "product_code": "113SCR2"
            },
            {
              "product": "loudspeaker_unit",
              "image_path": "product_imagesS113/loudspeaker_unit_2.jpg",
              "product_code": "113SCL1"
            },
            {
              "product": "loudspeaker_unit",
              "image_path": "product_imagesS113/loudspeaker_unit_3.jpg",
              "product_code": "113SCL1"
            },
            {
              "product": "induction_coil_set",
              "image_path": "product_imagesS113/induction_coil_set_3.jpg",
              "product_code": "101SR112SCI3"
            },
            {
              "product": "power_supply_unit_0_30V",
              "image_path": "product_imagesS113/power_supply_unit_0_30V_3.jpg",
              "product_code": "101SR112SCP2"
            },
            {
              "product": "batteries_1.5V_AA",
              "image_path": "product_imagesS113/batteries_1.5V_AA_3.jpg",
              "product_code": "101SR112SCB1"
            },
            {
              "product": "batteries_9V",
              "image_path": "product_imagesS113/batteries_9V_5.jpg",
              "product_code": "101SR112SCB2"
            },
            {
              "product": "battery_holders_assorted",
              "image_path": "product_imagesS113/battery_holders_assorted_5.jpg",
              "product_code": "101SR112SCB3"
            },
            {
              "product": "digital_multimeter",
              "image_path": "product_imagesS113/digital_multimeter_2.jpg",
              "product_code": "101SR112SCD2"
            },
            {
              "product": "analog_ammeter",
              "image_path": "product_imagesS113/analog_ammeter_2.jpg",
              "product_code": "101SR112SCA1"
            },
            {
              "product": "enameled_copper_wire_assorted",
              "image_path": "product_imagesS113/enameled_copper_wire_assorted_3.jpg",
              "product_code": "113SCE2"
            },
            {
              "product": "connecting_wires_set",
              "image_path": "product_imagesS113/connecting_wires_set_5.jpg",
              "product_code": "101SR112SCW2"
            },
            {
              "product": "alligator_clips",
              "image_path": "product_imagesS113/alligator_clips_2.jpg",
              "product_code": "101SR112SCA3"
            },
            {
              "product": "banana_plugs",
              "image_path": "product_imagesS113/banana_plugs_2.jpg",
              "product_code": "101SR112SCB4"
            },
            {
              "product": "banana_plugs",
              "image_path": "product_imagesS113/banana_plugs_3.jpg",
              "product_code": "101SR112SCB4"
            },
            {
              "product": "Oersted`s_law_apparatus",
              "image_path": "product_imagesS113/Oersted`s_law_apparatus_3.jpg",
              "product_code": "113SCO1"
            },
            {
              "product": "Faraday`s_law_demonstration_kit",
              "image_path": "product_imagesS113/Faraday`s_law_demonstration_kit_5.jpg",
              "product_code": "113SCF1"
            },
            {
              "product": "brushes_for_motors",
              "image_path": "product_imagesS113/brushes_for_motors_2.jpg",
              "product_code": "113SCB3"
            },
            {
              "product": "motor_winding_kit",
              "image_path": "product_imagesS113/motor_winding_kit_3.jpg",
              "product_code": "113SCM5"
            },
            {
              "product": "hand_crank_generator",
              "image_path": "product_imagesS113/hand_crank_generator_3.jpg",
              "product_code": "101SR112SCH1"
            },
            {
              "product": "hand_crank_generator",
              "image_path": "product_imagesS113/hand_crank_generator_5.jpg",
              "product_code": "101SR112SCH1"
            },
            {
              "product": "AC_generator_model",
              "image_path": "product_imagesS113/AC_generator_model_3.jpg",
              "product_code": "101SR112SCA4"
            },
            {
              "product": "wind_turbine_model",
              "image_path": "product_imagesS113/wind_turbine_model_5.jpg",
              "product_code": "113SCW1"
            },
            {
              "product": "transformer_core_set",
              "image_path": "product_imagesS113/transformer_core_set_2.jpg",
              "product_code": "113SCT1"
            },
            {
              "product": "step-down_transformer_model",
              "image_path": "product_imagesS113/step-down_transformer_model_4.jpg",
              "product_code": "113SCS4"
            },
            {
              "product": "ferrofluid_demonstration_kit",
              "image_path": "product_imagesS113/ferrofluid_demonstration_kit_5.jpg",
              "product_code": "113SCF2"
            },
            {
              "product": "Helmholtz_coils",
              "image_path": "product_imagesS113/Helmholtz_coils_2.jpg",
              "product_code": "113SCH2"
            },
            {
              "product": "cyclotron_model",
              "image_path": "product_imagesS113/cyclotron_model_5.jpg",
              "product_code": "113SCC3"
            },
            {
              "product": "Van_de_Graaff_generator",
              "image_path": "product_imagesS113/Van_de_Graaff_generator_2.jpg",
              "product_code": "101SR112SCV2"
            },
            {
              "product": "electromagnetic_crane",
              "image_path": "product_imagesS113/electromagnetic_crane_4.jpg",
              "product_code": "113SCE5"
            },
            {
              "product": "right-hand_rule_demonstrator",
              "image_path": "product_imagesS113/right-hand_rule_demonstrator_2.jpg",
              "product_code": "113SCR4"
            },
            {
              "product": "Fleming`s_right-hand_rule_model",
              "image_path": "product_imagesS113/Fleming`s_right-hand_rule_model_3.jpg",
              "product_code": "113SCF5"
            },
            {
              "product": "Hall_effect_demonstration_kit",
              "image_path": "product_imagesS113/Hall_effect_demonstration_kit_4.jpg",
              "product_code": "113SCH3"
            }
          
      
  ];