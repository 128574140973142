import React, { useState, useRef, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, Text } from '@react-three/drei';
import { Box, Typography, Grid, Paper, Select, MenuItem, FormControl, InputLabel, Slider } from '@mui/material';
import { scaleLinear } from 'd3-scale';

import elementsData from './elementsData.json';
import elementPositions from './elementPositions.json';

const ElementCube = ({ element, setSelectedElement, colorMode }) => {
  const color = getElementColor(element, colorMode);
  const position = elementPositions.positions[element.atomicNumber];
  if (!position) return null;

  return (
    <mesh 
      position={[position[0] - 8.5, -position[1] + 4.5, 0]} 
      onClick={() => setSelectedElement(element)}
    >
      <boxGeometry args={[0.9, 0.9, 0.1]} />
      <meshStandardMaterial color={color} />
      <Text
        position={[0, 0.2, 0.06]}
        fontSize={0.3}
        color="white"
        anchorX="center"
        anchorY="middle"
      >
        {element.symbol}
      </Text>
      <Text
        position={[0, -0.2, 0.06]}
        fontSize={0.15}
        color="white"
        anchorX="center"
        anchorY="middle"
      >
        {element.atomicNumber}
      </Text>
    </mesh>
  );
};

const Electron = ({ radius, speed, offset }) => {
  const [rotation, setRotation] = useState(0);
  useFrame((state, delta) => setRotation((prev) => prev + speed * delta));
  return (
    <mesh position={[Math.cos(rotation + offset) * radius, Math.sin(rotation + offset) * radius, 0]}>
      <sphereGeometry args={[0.1, 16, 16]} />
      <meshStandardMaterial color="#3498db" />
    </mesh>
  );
};

const ElectronShell = ({ radius, electrons }) => (
  <group>
    {electrons.map((_, index) => (
      <Electron key={index} radius={radius} speed={0.5} offset={(Math.PI * 2 / electrons.length) * index} />
    ))}
    <mesh>
      <ringGeometry args={[radius - 0.05, radius + 0.05, 64]} />
      <meshBasicMaterial color="#bdc3c7" transparent opacity={0.3} />
    </mesh>
  </group>
);

const AtomicStructure = ({ element }) => {
  const groupRef = useRef();

  useFrame((state) => {
    if (groupRef.current) {
      groupRef.current.rotation.y += 0.005;
    }
  });

  if (!element) return null;

  const electronConfig = element.atomicStructure.electronConfiguration;
  const shells = electronConfig.split(' ').reduce((acc, shell) => {
    const [, count] = shell.match(/(\d+)([spdf])(\d+)/) || [];
    if (count) acc.push(parseInt(count));
    return acc;
  }, []);

  return (
    <group ref={groupRef} position={[0, 0, 0]} scale={[1, 1, 1]}>
      <mesh>
        <sphereGeometry args={[0.5, 32, 32]} />
        <meshStandardMaterial color="#e74c3c" />
      </mesh>

      {shells.map((electrons, index) => (
        <ElectronShell key={index} radius={(index + 1) * 1.5} electrons={Array(electrons).fill()} />
      ))}
    </group>
  );
};

const InfoText = ({ position, title, content }) => {
  return (
    <group position={position}>
      <Text
        position={[0, 2.5, 0]}
        fontSize={0.4}
        color="black"
        anchorX="center"
        anchorY="top"
      >
        {title}
      </Text>
      {content.map((line, index) => (
        <Text
          key={index}
          position={[0, 2 - index * 0.3, 0]}
          fontSize={0.2}
          color="black"
          anchorX="center"
          anchorY="top"
        >
          {line}
        </Text>
      ))}
    </group>
  );
};

const AtomicInfoText = ({ element }) => {
  if (!element) return null;
  const content = [
    `Symbol: ${element.symbol}`,
    `Atomic Number: ${element.atomicNumber}`,
    `Atomic Mass: ${element.atomicMass}`,
    `Electron Config: ${element.atomicStructure.electronConfiguration}`,
    `Electronegativity: ${element.electronegativity || 'N/A'}`,
    `Ionization Energy: ${element.ionizationEnergy} kJ/mol`,
    `Atomic Radius: ${element.atomicRadius} pm`
  ];
  return <InfoText position={[6, 0, 0]} title={`${element.name} (Atomic Info)`} content={content} />;
};

const ApplicationInfoText = ({ element }) => {
  if (!element) return null;
  const content = [
    `Group: ${element.group}`,
    `Possible States: ${element.possibleStates.join(', ')}`,
    `Melting Point: ${element.meltingPoint}°C`,
    `Boiling Point: ${element.boilingPoint}°C`,
    `Found In: ${element.foundIn.join(', ')}`,
    'Applications:',
    ...element.applications.map(app => `• ${app}`)
  ];
  return <InfoText position={[5, 0, 18]} title={`${element.name} (Applications)`} content={content} />;
};

const ColorLegend = ({ colorMode }) => {
  const getLegendItems = () => {
    switch (colorMode) {
      case 'group':
        return (
          <Box sx={{ maxHeight: 150, overflowY: 'auto' }}>
            {Object.entries(groupColors).map(([group, color]) => (
              <Box key={group} display="flex" alignItems="center" mb={1}>
                <Box width={20} height={20} bgcolor={color} mr={1} />
                <Typography noWrap>{group}</Typography>
              </Box>
            ))}
          </Box>
        );
      case 'electronegativity':
      case 'atomicRadius':
      case 'atomicNumber':
      case 'meltingPoint':
      case 'ionizationEnergy':
        const scale = getColorScale(colorMode);
        return (
          <Box>
            <Box width="100%" height={20} sx={{ background: `linear-gradient(to right, ${scale.range()[0]}, ${scale.range()[1]})` }} />
            <Box display="flex" justifyContent="space-between" width="100%" mt={1}>
              <Typography>{scale.domain()[0]}</Typography>
              <Typography>{scale.domain()[1]}</Typography>
            </Box>
          </Box>
        );
      case 'possibleStates':
        return (
          <Box>
            {Object.entries(stateColors).map(([state, color]) => (
              <Box key={state} display="flex" alignItems="center" mb={1}>
                <Box width={20} height={20} bgcolor={color} mr={1} />
                <Typography>{state}</Typography>
              </Box>
            ))}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mt: 2, backgroundColor: '#f0f4f8' }}>
      <Typography variant="h6" color="#2c3e50">Color Legend</Typography>
      {getLegendItems()}
    </Paper>
  );
};

const PeriodicTable = ({ setSelectedElement, colorMode, selectedElement, viewLevel }) => {
  const { camera } = useThree();
  
  useEffect(() => {
    switch (viewLevel) {
      case 0:
        camera.position.set(0, 0, 25);
        break;
      case 1:
        camera.position.set(0, 0, 13);
        break;
      case 2:
        camera.position.set(0, 0, 10);
        break;
    }
  }, [viewLevel, camera]);

  return (
    <>
      <color attach="background" args={['#F0F8FF']} />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <OrbitControls enableZoom={true} enablePan={true} enableRotate={true} />
      
      {/* Fixed "Periodic Table" text */}
      <Text
        position={[0, 5, 0]}
        fontSize={1}
        color="black"
        anchorX="center"
        anchorY="middle"
      >
        Periodic Table
      </Text>

      {/* Selected element name */}
      {selectedElement && (
        <Text
          position={[0, 4, 0]}
          fontSize={0.8}
          color="black"
          anchorX="center"
          anchorY="middle"
        >
          {selectedElement.name}
        </Text>
      )}

      {viewLevel < 2 && elementsData.elements.map((element) => (
        <ElementCube
          key={element.symbol}
          element={element}
          setSelectedElement={setSelectedElement}
          colorMode={colorMode}
        />
      ))}
      {viewLevel === 2 && selectedElement && <AtomicStructure element={selectedElement} />}
      {selectedElement && (
        <>
          {viewLevel === 2 && <AtomicInfoText element={selectedElement} />}
          <ApplicationInfoText element={selectedElement} />
        </>
      )}
    </>
  );
};

const PeriodicTableExplorer = () => {
  const [selectedElement, setSelectedElement] = useState(null);
  const [colorMode, setColorMode] = useState('group');
  const [viewLevel, setViewLevel] = useState(1);

  return (
    <Box sx={{ backgroundColor: '#F0F8FF', p: 2, height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2} sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Paper elevation={3} sx={{ p: 2, backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
            <Typography variant="h6" color="#2c3e50">Controls</Typography>
            <FormControl sx={{ mt: 2 }}>
              <InputLabel id="color-mode-label">Color Mode</InputLabel>
              <Select
                labelId="color-mode-label"
                value={colorMode}
                label="Color Mode"
                onChange={(e) => setColorMode(e.target.value)}
              >
                <MenuItem value="group">Group</MenuItem>
                <MenuItem value="electronegativity">Electronegativity</MenuItem>
                <MenuItem value="atomicRadius">Atomic Radius</MenuItem>
                <MenuItem value="atomicNumber">Atomic Number</MenuItem>
                <MenuItem value="meltingPoint">Melting Point</MenuItem>
                <MenuItem value="ionizationEnergy">Ionization Energy</MenuItem>
                <MenuItem value="possibleStates">Possible States</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ mt: 2 }}>
              <Typography id="view-level-slider" gutterBottom>
                View Level: {['Application', 'Periodic Table', 'Atomic'][Math.round(viewLevel)]}
              </Typography>
              <Slider
                aria-labelledby="view-level-slider"
                value={viewLevel}
                onChange={(_, newValue) => setViewLevel(newValue)}
                min={0}
                max={2}
                step={0.01}
                marks={[
                  { value: 0, label: 'Application' },
                  { value: 1, label: 'Periodic Table' },
                  { value: 2, label: 'Atomic' },
                ]}
              />
            </Box>
            <ColorLegend colorMode={colorMode} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} sx={{ height: '100%' }}>
          <Paper elevation={3} sx={{ height: '100%', backgroundColor: '#F0F8FF' }}>
            <Canvas>
              <PeriodicTable 
                setSelectedElement={setSelectedElement} 
                colorMode={colorMode} 
                selectedElement={selectedElement}
                viewLevel={viewLevel}
              />
            </Canvas>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const groupColors = {
  '1 (Alkali metals)': '#ff6b6b',
  '2 (Alkaline earth metals)': '#feca57',
  '3-12 (Transition metals)': '#48dbfb',
  '13 (Boron group)': '#ff9ff3',
  '14 (Carbon group)': '#54a0ff',
  '15 (Pnictogens)': '#5f27cd',
  '16 (Chalcogens)': '#ff6b6b',
  '17 (Halogens)': '#ff9ff3',
  '18 (Noble gases)': '#48dbfb',
  'Lanthanides': '#feca57',
  'Actinides': '#54a0ff'
};

const stateColors = {
  'gas': '#ff7f50',
  'liquid': '#1e90ff',
  'solid': '#32cd32',
  'plasma': '#ff00ff'
};

const getColorScale = (colorMode) => {
  switch (colorMode) {
    case 'electronegativity':
      return scaleLinear().domain([0, 4]).range(['#ffffff', '#4a90e2']);
    case 'atomicRadius':
      return scaleLinear().domain([0, 300]).range(['#ffffff', '#4a90e2']);
    case 'atomicNumber':
      return scaleLinear().domain([1, 118]).range(['#ffd700', '#ff4500']);
    case 'meltingPoint':
      return scaleLinear().domain([-272, 3500]).range(['#00ffff', '#ff0000']);
    case 'ionizationEnergy':
      return scaleLinear().domain([0, 2500]).range(['#90ee90', '#800080']);
    default:
      return scaleLinear().domain([0, 1]).range(['#ffffff', '#4a90e2']);
  }
};
const getElementColor = (element, colorMode) => {
    switch (colorMode) {
      case 'group':
        return groupColors[element.group] || '#4a90e2';
      case 'possibleStates':
        return stateColors[element.possibleStates[0]] || '#808080';
      default:
        const scale = getColorScale(colorMode);
        return scale(element[colorMode] || 0);
    }
  };
  
  export default PeriodicTableExplorer;