import React from 'react';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import * as THREE from 'three';

const IndiaMapBase = ({ scale = 1 }) => {
  const texture = useLoader(TextureLoader, 'https://kshira-sagara.s3.ap-south-1.amazonaws.com/landing-page/social/textures/map.PNG');
  
  // Adjust these values based on your map texture's aspect ratio
  const mapWidth = 500 * scale;
  const mapHeight = 520 * scale;

  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.1, 0]}>
      <planeGeometry args={[mapWidth, mapHeight]} />
      <meshBasicMaterial map={texture} transparent={true} opacity={0.8} side={THREE.DoubleSide} />
    </mesh>
  );
};

export default IndiaMapBase;