// export const programmesContent = {
//     cardContent: 'Spark the ignition of Learning',
//     buttons: ['Competition for B.Ed Students'],
//     modalContent: {
//       'Competition for B.Ed Students': {
//         left: {
//           title: 'B.Ed Student Competition',
//           subtitle: 'Igniting Innovative Education',
//           paragraphs: [
//             'Our competition aims to spark creativity in future educators, challenging B.Ed students to develop innovative teaching methods for 21st-century learners.',
//             'The competition consists of two rounds, each designed to test and enhance different aspects of modern teaching techniques.',
//             'Round 1: Content Creation',
//             '• Select a chapter from any subject in Grades 8, 9, or 10.',
//             '• Create 20 thought-provoking questions based on the chosen chapter.',
//             '• Develop three innovative ways to make learning exciting, such as:',
//             '  - One-page summary',
//             '  - Visual lesson',
//             '  - MCQ challenge',
//             '  - Your own creative method',
//             'Round 2: Innovative Teaching Methods',
//             '• Choose a lesson from your Round 1 submission or a new topic.',
//             '• Develop a comprehensive lesson plan incorporating modern technology.',
//             '• Present your lesson plan and demonstrate its effectiveness.'
//           ],
//           ctaButton: {
//             text: 'Register Now',
//             onClick: () => console.log('Register button clicked')
//           }
//         },
//         right: {
//           content: [
//             {
//               title: 'Competition Details',
//               paragraphs: [
//                 'Round 1: Content Creation',
//                 '• Focus on crafting engaging learning materials',
//                 '• Create questions and innovative learning methods',
//                 '• Apply creativity to make learning exciting',
//                 '',
//                 'Round 2: Technology Integration',
//                 '• Incorporate modern technology into lessons',
//                 '• Explore platforms like Diksha for e-learning modules',
//                 '• Create online teacher communities',
//                 '• Develop methods to keep parents informed of student progress'
//               ]
//             },
//             {
//               title: 'Evaluation Criteria',
//               paragraphs: [
//                 '• Creativity in content and method',
//                 '• Effectiveness in promoting active learning',
//                 '• Alignment with modern pedagogical techniques',
//                 '• Innovative use of technology',
//                 '• Practicality and ease of implementation',
//                 '• Potential for improving student outcomes'
//               ]
//             },
//             {
//               title: 'Why Participate?',
//               paragraphs: [
//                 '• Enhance your teaching skills',
//                 '• Learn to integrate technology in education',
//                 '• Contribute to the evolution of teaching methods',
//                 '• Opportunity to win recognition and prizes'
//               ]
//             }
//           ],
//           photos: [
//             '/images/competition-1.jpg',
//             '/images/competition-2.jpg',
//             '/images/competition-3.jpg',
//             '/images/competition-4.jpg'
//           ],
//           description: 'These images showcase our B.Ed students applying innovative teaching methods and engaging with high school students.'
//         }
//       }
//     }
//   };








import React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { Paper, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { School, Create, Lightbulb, EmojiObjects } from '@mui/icons-material';

export const programmesContent = {
  cardContent: 'Spark the ignition of Learning',
  buttons: ['Devanagari B.Ed Programme', 'Balaganesh programme'],
  modalContent: {
    'Devanagari B.Ed Programme': {
      left: {
        title: 'Devanagari B.Ed Programme',
        subtitle: 'Devanagari B.Ed Competetion : The winner will receive a minimum cash prize of ₹5000, along with additional perks, while runners-up will also receive perks.',
        content: (
          <Timeline>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <School />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} sx={{ p: 5, bgcolor: 'background.paper' }}>
                  {/* <Typography variant="h6" component="h1">
                    Select Your Chapter
                  </Typography> */}
                  <Typography>Register.</Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Create />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} sx={{ p: 4, bgcolor: '' }}>
                  {/* <Typography variant="h6" component="h1">
                    Create Questions
                  </Typography> */}
                  <Typography>Recieve Instructions via WhatsApp.</Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Lightbulb />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} sx={{ p: 5, bgcolor: '' }}>
                  {/* <Typography variant="h6" component="h1">
                    Innovate Learning Methods
                  </Typography> */}
                  <Typography>Advance to the second round after selection.</Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <EmojiObjects />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} sx={{ p: 2, bgcolor: '' }}>
                  {/* <Typography variant="h6" component="h1">
                    Apply Your Creativity
                  </Typography> */}
                  <Typography>Winners and runners-up Announcement.</Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
          
        ),
        
        ctaButton: {
          text: 'Register Now',
          onClick: () => {
            window.open('https://forms.gle/vNAh6RMxEymGaPSEA', '_blank');
          }
        }
      },
      right: {
        title: 'Gallery',
        subtitle: 'B.Ed Students in Action',
        content: (
          <List>
            <ListItem>
              <ListItemIcon>
                <School />
              </ListItemIcon>
              <ListItemText 
                primary="Interactive Workshops" 
                secondary="Students participate in hands-on teaching simulations."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Lightbulb />
              </ListItemIcon>
              <ListItemText 
                primary="Innovative Teaching Methods" 
                secondary="Exploring new ways to engage students in the learning process."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <EmojiObjects />
              </ListItemIcon>
              <ListItemText 
                primary="Technology Integration" 
                secondary="Learning to incorporate modern tech tools in education."
              />
              
            </ListItem>
            <ListItemText secondary="These images showcase our B.Ed students demonstrating their newly acquired teaching skills in a Resource-Limited School Kudligi, Vijayanagara."
      />
          </List>
        ),
        photos: [
          '/images/Reflection.jpeg',
          '/images/Reflection2.jpeg',
          '/images/Reflection3.jpeg',
          // '/images/Reflection4.jpeg',
          '/images/Reflection5.jpeg',
        ],
       }
    },
    'Balaganesh programme': {
      left: {
        title: 'Balaganesh Programme',
        subtitle: 'Enhancing Reading and Writing Skills',
        content: (
          <Paper elevation={3} sx={{ p: 2, bgcolor: 'background.paper' }}>
            <Typography variant="h6" gutterBottom>
              Program Highlights
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Create />
                </ListItemIcon>
                <ListItemText 
                  primary="Intensive Writing Workshops" 
                  secondary="Develop strong writing skills through guided practice and feedback."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <School />
                </ListItemIcon>
                <ListItemText 
                  primary="Reading Comprehension Techniques" 
                  secondary="Learn strategies to improve understanding and retention of written material."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmojiObjects />
                </ListItemIcon>
                <ListItemText 
                  primary="Creative Expression" 
                  secondary="Explore various forms of written expression to boost creativity."
                />
              </ListItem>
            </List>
          </Paper>
        ),
        ctaButton: {
          text: 'Connect with Us',
          onClick: () => {
            window.open('https://forms.gle/z1QT2ug2c3fJASPT6', '_blank');
          }
        }
      },
      right: {
        title: 'Programme Benefits',
        subtitle: 'Empowering Through Literacy',
        content: (
          <Paper elevation={3} sx={{ p: 2, bgcolor: 'background.paper' }}>
            <Typography variant="body1" paragraph>
              The Balaganesh programme is designed to empower students with essential reading and writing skills. Through our comprehensive approach, participants will:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Lightbulb />
                </ListItemIcon>
                <ListItemText primary="Enhance critical thinking and analysis" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Create />
                </ListItemIcon>
                <ListItemText primary="Improve communication skills" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <School />
                </ListItemIcon>
                <ListItemText primary="Boost academic performance across subjects" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmojiObjects />
                </ListItemIcon>
                <ListItemText primary="Develop a lifelong love for learning" />
              </ListItem>
            </List>
          </Paper>
        ),
        description: 'Join us in our mission to create a generation of confident readers and articulate writers.',
      }
    }
  }
};