import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import Header from '../Header/Header'; // Import the Header component

const WaveShape = styled('path')`
  @keyframes waveAnimation {
    0% {
      d: path("M0,320 C240,320 480,160 720,160 L960,160 C1200,160 1320,80 1380,40 L1440,0 L1440,320 L0,320 Z");
    }
    50% {
      d: path("M0,320 C240,300 480,240 720,240 L960,240 C1200,240 1320,180 1380,120 L1440,80 L1440,320 L0,320 Z");
    }
    100% {
      d: path("M0,320 C240,320 480,160 720,160 L960,160 C1200,160 1320,80 1380,40 L1440,0 L1440,320 L0,320 Z");
    }
  }
  animation: waveAnimation 10s ease-in-out infinite;
`;

const WaveDivider = () => (
  <Box
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1440 320"
    preserveAspectRatio="none"
    sx={{
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '320px',
      zIndex: 1,
      left: 0,
      right: 0,
    }}
  >
    <WaveShape
      fill="#FFFFFF"
      fillOpacity="1"
      d="M0,320 C240,320 480,160 720,160 L960,160 C1200,160 1320,80 1380,40 L1440,0 L1440,320 L0,320 Z"
    />
  </Box>
);

const Layout = ({ children, selectedHeader, onHeaderClick }) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflow: 'hidden',
  }}>
    <Header selectedHeader={selectedHeader} onHeaderClick={onHeaderClick} />
    <Box sx={{
      flex: 1,
      background: 'linear-gradient(to bottom, #1F3D58 0%, #07101C 100%)',
      padding: 2,
      position: 'relative',
      minHeight: 'calc(100vh - 64px)', // Adjust based on your header height
      overflowY: 'auto',
    }}>
      {children}
      <WaveDivider />
    </Box>
    <Box sx={{
      backgroundColor: '#FFFFFF',
      position: 'relative',
      zIndex: 2,
      flexGrow: 1,
    }} />
  </Box>
);

export default Layout;