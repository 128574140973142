import React from 'react';
import { Grid } from '@mui/material';

const PhotoGrid = ({ photos }) => (
  <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
    {photos.map((photo, index) => (
      <Grid item xs={6} sm={3} key={index}>
        <img 
          src={photo} 
          alt={`Product ${index + 1}`} 
          style={{ width: '100%', height: 'auto', borderRadius: '8px' }} 
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/images/placeholder-image.jpg';
          }}
        />
      </Grid>
    ))}
  </Grid>
);

export default PhotoGrid;