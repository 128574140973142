import React, { useRef, useEffect } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const CameraController = ({ targetPosition, targetZoom }) => {
  const { camera } = useThree();
  const currentPosition = useRef(new THREE.Vector3(0, 0, 200));
  const currentZoom = useRef(1);
  const lerpFactor = useRef(0.05);

  useEffect(() => {
    camera.position.copy(currentPosition.current);
    camera.lookAt(0, 0, 0);
  }, [camera]);

  useFrame(() => {
    if (Array.isArray(targetPosition) && targetPosition.length === 3) {
      const target = new THREE.Vector3(...targetPosition);
      currentPosition.current.lerp(target, lerpFactor.current);
      camera.position.copy(currentPosition.current);
      
      currentZoom.current = THREE.MathUtils.lerp(currentZoom.current, targetZoom, lerpFactor.current);
      camera.zoom = currentZoom.current;
      camera.updateProjectionMatrix();
      
      camera.lookAt(0, 0, 0);
      
      lerpFactor.current = Math.max(lerpFactor.current * 0.65, 0.01);
    } else {
      lerpFactor.current = 0.05;
    }
  });

  return null;
};

export default CameraController;