import React from 'react';
import { Typography, Box } from '@mui/material';

const ModalContent = ({ content, headerColor }) => {
  if (Array.isArray(content.content)) {
    return content.content.map((section, index) => (
      <Box key={index} sx={{ mb: 2 }}>
        {section.title && (
          <Typography variant="h5" gutterBottom sx={{ color: headerColor, fontWeight: 'bold' }}>
            {section.title}
          </Typography>
        )}
        {section.subtitle && (
          <Typography variant="subtitle1" gutterBottom sx={{ color: headerColor, marginBottom: 2 }}>
            {section.subtitle}
          </Typography>
        )}
        {section.paragraphs && section.paragraphs.map((paragraph, pIndex) => (
          <Typography key={pIndex} paragraph>
            {paragraph}
          </Typography>
        ))}
      </Box>
    ));
  } else {
    return (
      <>
        {content.title && (
          <Typography variant="h5" gutterBottom sx={{ color: headerColor, fontWeight: 'bold' }}>
            {content.title}
          </Typography>
        )}
        {content.subtitle && (
          <Typography variant="subtitle1" gutterBottom sx={{ color: headerColor, marginBottom: 2 }}>
            {content.subtitle}
          </Typography>
        )}
        {content.paragraphs && content.paragraphs.map((paragraph, index) => (
          <Typography key={index} paragraph>
            {paragraph}
          </Typography>
        ))}
        {content.description && (
          <Typography paragraph sx={{ mt: 2, fontStyle: 'italic', color: 'text.secondary' }}>
            {content.description}
          </Typography>
        )}
      </>
    );
  }
};

export default ModalContent;