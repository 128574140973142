// import { productData } from './productData';
import { Grid } from '@mui/material';
import TLMCard from './TLMCard';  // Make sure to import the TLMCard component
import { tlmData } from './tlmData';  // Import the TLM data



export const productsContent = {
  cardContent: 'Explore our innovative educational products',
  buttons: ['TLMs', 'School Accessories'],
  modalContent: {
    TLMs: {
      left: {
        title: 'Teaching and Learning Materials',
        paragraphs: ['Discover our range of innovative TLMs to enhance the learning experience.Register to Explore all the Materials. ']
      },
      right: {
        title: 'Our TLM Collection',
        content: (
          <Grid container spacing={2}>
            {tlmData.map((tlm, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <TLMCard name={tlm.product} image={tlm.image_path} productCode={tlm.product_code} />
              </Grid>
            ))}
          </Grid>
        )
      }    },
    'School Accessories': {
      left: {
        title: 'School Accessories',
        paragraphs: ['Equip your classroom with our range of high-quality school accessories.']
      },
      right: {
        title: 'Our Accessories Range',
        paragraphs: ['Register to get access for School Accessories']
      //   photos: [
      //     '/images/PH.jpeg',
      //     '/path/to/accessory2.jpg',
      //     '/path/to/accessory3.jpg',
      //     '/path/to/accessory4.jpg',
      //     '/path/to/accessory5.jpg',
      //     '/path/to/accessory6.jpg',
      //     '/path/to/accessory7.jpg',
      //     '/path/to/accessory8.jpg'
      //   ]
      }
    }
  }
};