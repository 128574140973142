import React, { useState, useRef, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  Container, 
  Grid, 
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
  Snackbar,
  Alert
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a custom theme (keep your existing theme)
const theme = createTheme({
  palette: {
    background: {
      default: '#F7F7F5',
    },
    primary: {
      main: '#4A90E2',
    },
    secondary: {
      main: '#50C878',
    },
  },
});

const SetTheoryWorld = () => {
  const [sets, setSets] = useState({ A: new Set(), B: new Set() });
  const [currentSet, setCurrentSet] = useState('A');
  const [inputValue, setInputValue] = useState('');
  const [activeOperation, setActiveOperation] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const canvasRef = useRef(null);
  const spheresRef = useRef({});
  const sceneRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    // Three.js setup (keep your existing setup)
    const scene = new THREE.Scene();
    sceneRef.current = scene;
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, alpha: true });
    renderer.setSize(400, 400);

    const controls = new OrbitControls(camera, renderer.domElement);
    camera.position.z = 5;

    const createSphere = (color) => {
      const geometry = new THREE.SphereGeometry(1, 32, 32);
      const material = new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 0.7 });
      return new THREE.Mesh(geometry, material);
    };

    spheresRef.current = {
      A: createSphere(0xff9999),
      B: createSphere(0x99ff99)
    };

    updateSpheresPosition();

    Object.values(spheresRef.current).forEach(sphere => scene.add(sphere));

    const animate = () => {
      animationRef.current = requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    return () => {
      cancelAnimationFrame(animationRef.current);
      renderer.dispose();
    };
  }, []);

  useEffect(() => {
    updateSpheresSize();
    updateSpheresPosition();
    // Reset active operation when sets change
    setActiveOperation(null);
  }, [sets]);

  const updateSpheresSize = () => {
    Object.entries(sets).forEach(([setName, setValues]) => {
      const sphere = spheresRef.current[setName];
      const targetScale = Math.max(0.5, Math.min(2, 0.5 + setValues.size * 0.2));
      sphere.scale.set(targetScale, targetScale, targetScale);
    });
  };

  const updateSpheresPosition = () => {
    const sphereA = spheresRef.current.A;
    const sphereB = spheresRef.current.B;
    const totalRadius = sphereA.scale.x + sphereB.scale.x;
    sphereA.position.set(-totalRadius / 2, 0, 0);
    sphereB.position.set(totalRadius / 2, 0, 0);
  };

  const calculateIntersectionPercentage = () => {
    const intersectionSize = new Set([...sets.A].filter(x => sets.B.has(x))).size;
    const unionSize = new Set([...sets.A, ...sets.B]).size;
    return unionSize > 0 ? intersectionSize / unionSize : 0;
  };

  const animateSetOperation = (operation) => {
    const sphereA = spheresRef.current.A;
    const sphereB = spheresRef.current.B;
    const duration = 1000; // ms
    const startTime = Date.now();
    const intersectionPercentage = calculateIntersectionPercentage();
    const totalRadius = sphereA.scale.x + sphereB.scale.x;
    const initialPositionA = sphereA.position.x;
    const initialPositionB = sphereB.position.x;

    function update() {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      if (operation === 'intersection') {
        const overlapDistance = totalRadius * intersectionPercentage;
        const targetPositionA = -totalRadius / 2 + overlapDistance / 2;
        const targetPositionB = totalRadius / 2 - overlapDistance / 2;
        
        sphereA.position.x = initialPositionA + (targetPositionA - initialPositionA) * progress;
        sphereB.position.x = initialPositionB + (targetPositionB - initialPositionB) * progress;
      } else if (operation === 'union') {
        const targetPosition = 0;
        sphereA.position.x = initialPositionA + (targetPosition - initialPositionA) * progress;
        sphereB.position.x = initialPositionB + (targetPosition - initialPositionB) * progress;
      }

      if (progress < 1) {
        requestAnimationFrame(update);
      } else {
        // Animation complete
        setActiveOperation(operation);
      }
    }

    update();
  };

  const resetSpheres = () => {
    updateSpheresPosition();
    setActiveOperation(null);
  };

  const handleSetChange = (event, newSet) => {
    if (newSet !== null) {
      setCurrentSet(newSet);
      // Reset active operation when changing sets
      setActiveOperation(null);
      resetSpheres();
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    if (inputValue.trim() !== '') {
      const newElement = inputValue.trim();
      setSets(prevSets => {
        if (prevSets[currentSet].has(newElement)) {
          setOpenSnackbar(true);
          return prevSets;
        }
        // Reset active operation when modifying sets
        setActiveOperation(null);
        resetSpheres();
        return {
          ...prevSets,
          [currentSet]: new Set([...prevSets[currentSet], newElement])
        };
      });
      setInputValue('');
    }
  };

  const performSetOperation = (operation) => {
    if (activeOperation === operation) {
      resetSpheres();
    } else {
      animateSetOperation(operation);
    }
  };

  const getOperationResult = () => {
    if (activeOperation === 'union') {
      return new Set([...sets.A, ...sets.B]);
    } else if (activeOperation === 'intersection') {
      return new Set([...sets.A].filter(x => sets.B.has(x)));
    }
    return new Set();
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', py: 4 }}>
        <Container maxWidth="lg">
          <Typography variant="h3" component="h1" gutterBottom align="center">
            World of Mathematics: Set Theory
          </Typography>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <ToggleButtonGroup
                      value={currentSet}
                      exclusive
                      onChange={handleSetChange}
                      aria-label="set selection"
                      fullWidth
                    >
                      <ToggleButton value="A" aria-label="Set A">Set A</ToggleButton>
                      <ToggleButton value="B" aria-label="Set B">Set B</ToggleButton>
                    </ToggleButtonGroup>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <TextField
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder={`Add element to Set ${currentSet}`}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      <Button variant="contained" onClick={handleSubmit} color="primary">
                        Add
                      </Button>
                    </Box>
                    <Button 
                      variant="outlined" 
                      onClick={() => performSetOperation('union')}
                      color={activeOperation === 'union' ? "secondary" : "primary"}
                    >
                      {activeOperation === 'union' ? "Reset Union" : "Show Union"}
                    </Button>
                    <Button 
                      variant="outlined" 
                      onClick={() => performSetOperation('intersection')}
                      color={activeOperation === 'intersection' ? "secondary" : "primary"}
                    >
                      {activeOperation === 'intersection' ? "Reset Intersection" : "Show Intersection"}
                    </Button>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="h6" gutterBottom>Current Sets:</Typography>
                      {Object.entries(sets).map(([setName, setValues]) => (
                        <Typography key={setName} variant="body1" gutterBottom>
                          Set {setName}: {[...setValues].join(', ')}
                        </Typography>
                      ))}
                      {activeOperation && (
                        <Typography variant="body1" gutterBottom>
                          {activeOperation.charAt(0).toUpperCase() + activeOperation.slice(1)} (A {activeOperation === 'union' ? '∪' : '∩'} B): {[...getOperationResult()].join(', ')}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ width: 400, height: 400, border: '1px solid #ccc' }}>
                    <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
        An element cannot be added to a set more than once. Please ensure each element is unique.
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default SetTheoryWorld;