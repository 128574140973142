import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Container, IconButton, Popover, useMediaQuery, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import HeaderButton from './HeaderButton';
import ProfilePopup from './ProfilePopup';
import { headerItems } from '../../data/index';

const Header = ({ selectedHeader, onHeaderClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const mobileMenuOpen = Boolean(mobileMenuAnchorEl);

  return (
    <AppBar position="fixed" color="transparent" elevation={0}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ flexDirection: 'column', alignItems: 'stretch', py: 1 }}>
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mb: 1
          }}>
            <Typography 
              variant="h6" 
              component="div" 
              sx={{ 
                color: 'white', 
                fontWeight: 'bold', 
                border: '2px solid white', 
                padding: '5px 10px',
                display: 'inline-block'
              }}
            >
              Devanagari
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!isMobile && (
                <Box sx={{ mr: 2 }}>
                  {headerItems.map((item, index) => (
                    <HeaderButton 
                      key={index} 
                      text={item} 
                      onClick={() => onHeaderClick(item)}
                      isActive={selectedHeader === item}
                    />
                  ))}
                </Box>
              )}
              {isMobile && (
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMobileMenuOpen}
                  sx={{ mr: 1 }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <IconButton color="inherit" aria-label="profile" onClick={handleClick}>
                <AccountCircleIcon sx={{ color: 'white' }} />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </Container>
      <Menu
        anchorEl={mobileMenuAnchorEl}
        open={mobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {headerItems.map((item, index) => (
          <MenuItem 
            key={index} 
            onClick={() => {
              onHeaderClick(item);
              handleMobileMenuClose();
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ProfilePopup onClose={handleClose} />
      </Popover>
    </AppBar>
  );
};

export default Header;