import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import EventNavCard from './EventNavCard';
import FreedomStruggleTimeline from './FreedomStruggleTimeline';
import CameraController from './CameraController';
import BackgroundImage from './BackgroundImage';
import { eventData } from './Event';

const ScienceLearningRoom = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const timelineRef = useRef();
  const timeoutRef = useRef(null);

  const events = Object.keys(eventData);

  const handleEventSelect = useCallback((eventName) => {
    setSelectedEvent(eventName);
    setCurrentEventIndex(events.indexOf(eventName));
    setIsPlaying(false);
  }, [events]);

  const handleMapView = useCallback(() => {
    setSelectedEvent(null);
    setIsPlaying(false);
  }, []);

  const getCameraSettings = useCallback(() => {
    return timelineRef.current?.getCameraSettings(selectedEvent);
  }, [selectedEvent]);

  const togglePlay = useCallback(() => {
    setIsPlaying(prev => !prev);
  }, []);

  useEffect(() => {
    if (isPlaying) {
      timeoutRef.current = setTimeout(() => {
        if (currentEventIndex < events.length - 1) {
          const nextEvent = events[currentEventIndex + 1];
          setCurrentEventIndex(prevIndex => prevIndex + 1);
          setSelectedEvent(nextEvent);
        } else {
          setIsPlaying(false);
        }
      }, 15000);
    } else {
      clearTimeout(timeoutRef.current);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [isPlaying, currentEventIndex, events]);

  return (
    <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
      <EventNavCard 
        onEventSelect={handleEventSelect}
        currentEventIndex={currentEventIndex}
      />
      <div style={{ 
        position: 'absolute', 
        top: '10px', 
        left: '50%', 
        transform: 'translateX(-50%)', 
        zIndex: 1000, 
        display: 'flex', 
        gap: '10px' 
      }}>
        <button 
          onClick={togglePlay}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            background: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          {isPlaying ? 'Read' : 'Play'}
        </button>
        <button 
          onClick={handleMapView}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            background: '#f44336',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Map View
        </button>
      </div>
      
      {!isPlaying && selectedEvent && (
        <div style={{ 
          position: 'absolute', 
          top: '50%', 
          right: '20px', 
          transform: 'translateY(-50%)',
          zIndex: 1000, 
          background: '#BA5B38', 
          color: 'white',
          padding: '20px', 
          borderRadius: '10px',
          width: '300px',
          maxHeight: '80vh',
          overflowY: 'auto'
        }}>
          <h3>{selectedEvent}</h3>
          <p>{eventData[selectedEvent].description}</p>
        </div>
      )}

      <Canvas>
        <PerspectiveCamera makeDefault position={[0, 0, 200]} far={1000} />
        <CameraController 
          targetPosition={getCameraSettings()?.position}
          targetZoom={getCameraSettings()?.zoom}
        />
        <ambientLight intensity={0.8} />
        <BackgroundImage imagePath="https://kshira-sagara.s3.ap-south-1.amazonaws.com/landing-page/social/textures/bg.PNG" />
        <FreedomStruggleTimeline ref={timelineRef} />
      </Canvas>
    </div>
  );
};

export default ScienceLearningRoom;