import React from 'react';
import { Box, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { buttonColors } from '../../data';

const AboutUsContent = ({ onButtonClick }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
      <Button
        variant="contained"
        endIcon={<ArrowForwardIcon />}
        sx={{
          backgroundColor: buttonColors['DevaNagari'],
          '&:hover': {
            backgroundColor: buttonColors['DevaNagari'],
            opacity: 0.8,
          },
          justifyContent: 'space-between',
          paddingRight: 2,
        }}
        onClick={(event) => onButtonClick('DevaNagari', event)}
      >
        Learn More
      </Button>
    </Box>
  );
};

export default AboutUsContent;