import React from 'react';
import { Box, IconButton, Paper, useMediaQuery, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ModalContent from './ModalContent';
import PhotoGrid from './PhotoGrid';

const ModalOverlay = ({ content, onClose, buttonColor }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderContent = (sectionContent) => {
    if (React.isValidElement(sectionContent.content)) {
      return (
        <>
          <ModalContent content={{
            title: sectionContent.title,
            subtitle: sectionContent.subtitle
          }} headerColor={buttonColor} />
          {sectionContent.content}
        </>
      );
    } else {
      return <ModalContent content={sectionContent} headerColor={buttonColor} />;
    }
  };

  const headerHeight = 64; // Adjust this value based on your actual header height

  return (
    <Box
      sx={{
        position: 'fixed',
        top: `${headerHeight}px`,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        display: 'flex',
        zIndex: 1000,
        overflowY: 'auto',
        transition: 'transform 0.3s ease-in-out',
        transform: 'translateY(0%)',
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%', 
        height: '100%',
        p: isMobile ? 2 : 4,
      }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: 'white',
            backgroundColor: buttonColor,
            '&:hover': {
              backgroundColor: buttonColor,
              opacity: 0.9,
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Paper sx={{ 
          width: isMobile ? '100%' : '30%',
          p: 3, 
          maxHeight: isMobile ? 'none' : `calc(100vh - ${headerHeight}px)`,
          overflow: 'auto',
          marginBottom: isMobile ? 2 : 0,
          marginRight: isMobile ? 0 : 2,
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        }}>
          {renderContent(content.left)}
          {content.left && content.left.ctaButton && (
            <Button 
              variant="contained" 
              sx={{ 
                mt: 2, 
                backgroundColor: buttonColor,
                '&:hover': {
                  backgroundColor: buttonColor,
                  opacity: 0.9,
                },
              }}
              onClick={content.left.ctaButton.onClick}
            >
              {content.left.ctaButton.text}
            </Button>
          )}
        </Paper>
        <Paper sx={{ 
          width: isMobile ? '100%' : '70%',
          p: 3, 
          maxHeight: isMobile ? 'none' : `calc(100vh - ${headerHeight}px)`,
          overflow: 'auto',
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        }}>
          {renderContent(content.right)}
          {content.right && content.right.photos && (
            <PhotoGrid photos={content.right.photos} />
          )}
          {content.right && content.right.ctaButton && (
            <Button 
              variant="contained" 
              sx={{ 
                mt: 2, 
                backgroundColor: buttonColor,
                '&:hover': {
                  backgroundColor: buttonColor,
                  opacity: 0.9,
                },
              }}
              onClick={content.right.ctaButton.onClick}
            >
              {content.right.ctaButton.text}
            </Button>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default ModalOverlay;