import React from 'react';
import { Button } from '@mui/material';

const HeaderButton = ({ text, onClick }) => (
  <Button 
    color="inherit" 
    sx={{ 
      mx: 1,
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
    }}
    onClick={onClick}
  >
    {text}
  </Button>
);

export default HeaderButton;