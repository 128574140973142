import React from 'react';
import { useThree, useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import * as THREE from 'three';

const BackgroundImage = ({ imagePath }) => {
  const texture = useLoader(TextureLoader, imagePath);
  const { scene } = useThree();

  React.useEffect(() => {
    const geometry = new THREE.SphereGeometry(500, 60, 40);
    geometry.scale(-1, 1, 1);

    const material = new THREE.MeshBasicMaterial({
      map: texture,
    });

    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    return () => {
      scene.remove(mesh);
      geometry.dispose();
      material.dispose();
    };
  }, [scene, texture]);

  return null;
};

export default BackgroundImage;