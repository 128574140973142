export const servicesContent = {
  cardContent: 'Comprehensive educational services',
  buttons: ['Software', 'Teacher\'s Training'],
  modalContent: {
    Software: {
      left: {
        title: 'Educational Software Solutions',
        paragraphs: [
          'Our cutting-edge software solutions are designed to revolutionize the way we teach and learn. From adaptive learning platforms to virtual reality experiences, we offer a range of tools to enhance engagement and improve learning outcomes.'
        ]
      },
      right: {
        paragraphs: ['Register to Explore our Software Solutions']
        // photos: [
        //   '/path/to/software1.jpg',
        //   '/path/to/software2.jpg',
        //   '/path/to/software3.jpg',
        //   '/path/to/software4.jpg',
        //   '/path/to/software5.jpg',
        //   '/path/to/software6.jpg'
        // ]
      }
    },
    'Teacher\'s Training': {
      left: {
        title: 'Professional Development for Educators',
        paragraphs: [
          'Our comprehensive teacher training programs are designed to equip educators with the latest pedagogical techniques, technology integration skills, and classroom management strategies. We believe in empowering teachers to create dynamic and effective learning environments.'
        ]
      },
      right: {
        paragraphs: ['It will be Rolled out from next year. connect with us to learn more .']
        // photos: [
        //   '/path/to/training1.jpg',
        //   '/path/to/training2.jpg',
        //   '/path/to/training3.jpg',
        //   '/path/to/training4.jpg',
        //   '/path/to/training5.jpg',
        //   '/path/to/training6.jpg'
        // ]
      }
    }
  }
};