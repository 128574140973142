import { aboutUsContent } from './aboutUs';
import { subjectsContent } from './subjects';
import { productsContent } from './products';
import { servicesContent } from './services';
import { programmesContent } from './programmes'
export { buttonColors } from './buttonColors';

export const headerItems = ['ABOUT US', 'SUBJECTS', 'PRODUCTS', 'SERVICES', 'PROGRAMMES'];

export const contentData = {
  'ABOUT US': {
    ...aboutUsContent,
    headerText: 'Comprehensive Learning is all about Immersive Experience.'
  },
  'SUBJECTS': {
    ...subjectsContent,
    headerText: 'Explore Our Learning Rooms'
  },
  'PRODUCTS': {
    ...productsContent,
    headerText: 'Educational Products'
  },
  'SERVICES': {
    ...servicesContent,
    headerText: 'Our Services'
  },
  'PROGRAMMES': {
    ...programmesContent,
    headerText: 'Educational Programmes'
  }
};