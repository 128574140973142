export const aboutUsContent = {
  cardContent: 'We help to transform education from rote memorization to a world of discovery. We blend traditional teaching and technology to spark curiosity and critical thinking.',
  buttons: ['Devanagari', 'People', 'Careers'],
  modalContent: {
    DevaNagari: {
      left: {
        title: 'Our Mission',
        paragraphs: [
          'At Devanagari, our mission is to empower schools with innovative tools and resources that transform education into a journey of exploration and discovery. We strive to create learning environments where students actively engage with concepts, solve problems, and become passionate participants in their own education.'
        ],
        ctaButton: {
          text: 'Connect with Us',
          onClick: () => {
            window.open('https://forms.gle/z1QT2ug2c3fJASPT6', '_blank');
          }
        }
      },
      right: {
        content: [
          {
            title: 'Who We Are',
            paragraphs: [
              'Devanagari is a comprehensive educational support company dedicated to enhancing the learning experience in schools. We offer a wide range of solutions designed to support both teachers and students in their educational journey.',
              'Our name, inspired by the ancient Devanagari script, represents our commitment to bridging traditional knowledge with modern educational techniques.'
            ]
          },
          {
            title: 'Our Approach',
            paragraphs: [
              'Our product line includes cutting-edge Teaching Learning Materials (TLMs), advanced educational software for classroom instruction and lesson preparation, and a variety of school accessories. By combining traditional teaching methods with modern technology, we aim to create a dynamic and effective learning environment that fosters curiosity, critical thinking, and problem-solving skills.'
            ]
          },
          {
            title: 'Ecosystem',
            paragraphs: [
              'Beyond the classroom, Devanagari is dedicated to creating an ecosystem that supports holistic educational development. Our range of school accessories complements our digital offerings, ensuring that the physical learning environment is as conducive to discovery as our virtual tools. We\'re also expanding our reach through programmes like Our Devanagari B.Ed Programme, in particular, exemplifies our commitment to nurturing innovative teaching styles that resonate with 21st-century learners. By continuously evolving our product line and services based on pedagogical research and real-world feedback, we\'re not just keeping pace with educational needs – we\'re anticipating and shaping them. Our ultimate goal is to create a ripple effect, where empowered teachers and engaged students collectively drive forward a more dynamic, inclusive, and effective educational landscape.'
            ]
          }
        ]
        
      }
    },
    People: {
      left: {
        title: 'Our Team',
        paragraphs: [
          'Devanagari is powered by a diverse team of educators, technologists, and visionaries who are passionate about transforming education.',
          'Our experts bring together years of experience in pedagogy, software development, and educational psychology to create truly impactful learning solutions.'
        ]
      },
      right: {
        title: 'Leadership',
        paragraphs: [
          ' - Founder & CEO',
          'Rajesh Kumar - Chief Technology Officer',
          'Emma Thompson - Head of Curriculum Development',
          'Liu Wei - Director of International Partnerships'
        ]
      }
    },
    Careers: {
      left: {
        title: 'Join Our Team',
        paragraphs: [
          'At Devanagari, we\'re always looking for passionate individuals who want to make a difference in education.',
          'We offer a dynamic work environment, opportunities for professional growth, and the chance to impact millions of learners worldwide.'
        ]
      },
      right: {
        title: 'Open Positions',
        paragraphs: [
          'Educational Content Developer',
          'Backend Developer - Python , Django',
          'Frontend Develper - React JS',
          'UX/UI Designer',
          'Machine Learning Engineer',
          'Customer Success Manager - Educational Institutions',
          'Video Editor - Youtube videos long form and short form'
        ]
      }
    }
  }
};