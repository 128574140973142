import React, { useImperativeHandle, forwardRef } from 'react';
import { Event, eventData } from './Event';
import IndiaMapBase from './IndiaMapBase';

const FreedomStruggleTimeline = forwardRef((props, ref) => {
  const eventSettings = {
    'British in India': { position: [-160, 8, -27], zoom: 2.8 },
    'Dalhousie and the Doctrine of Lapse': { position: [-340, 220, -120], zoom: 1.3 },
    'Mangal Pandey': { position: [150, 10, -70], zoom: 4.2},
    'The Delhi Attack of 1857': { position: [-70, 12, -185], zoom: 6.3 },
    'The Kanpur Attack': { position: [0, 8, -100], zoom: 2.3 },
    'The Lucknow Attack': { position: [22, 6, -110], zoom: 2.6 },
    'Annexation of Jhansi': { position: [-75, 10, -105], zoom: 2.8 },
    'Bihar Attack: Impact': { position: [60, 10, -93], zoom: 1.6 },
    'East India Company to British Raj': { position: [670, 280, -100], zoom: 2.3 },
    'Indian National Congress (1885)': { position: [-120, 10, 2], zoom: 0.3 },
    'The Partition of Bengal (1905)': { position: [150, 15, -39], zoom: 2.3 },
    'The Swadeshi Movement:': { position: [105, 7, -27], zoom: 1.3 },
    'National Congress session in Banaras': { position: [30, 7, -80], zoom: 1.3 },
    'The Shimla Deputation ': { position: [-53, 6, -177], zoom: 1.3 },
    'The 1907 Congress Split ': { position: [-118, 4, -20], zoom: 0.7 },
    'The Morley-Minto Reforms (1909)': { position: [180, 60, 0], zoom: 1.8 },
    'The Lucknow Pact (1916)': { position: [16, 5.5, -88], zoom: 0.5 },
    'Satyagraha (1917)': { position: [50, 5, -125], zoom: 1.4 },
    'The Kheda Satyagraha (1918)': { position: [-120, 5, -37], zoom: 1.2 },
    'Rowlatt Act: Jallianwala Bagh Massacre': { position: [-83, 10, -185], zoom: 1 },
    'The Non-Cooperation Movement (1920-1922)': { position: [-95, 10, 140], zoom: 1 },
    'Chauri Chaura Incident': { position: [35, 17, -103], zoom: 1.5 },
    'Hindustan Republican Association (HRA)': { position: [10, 8.7, -100], zoom: 1.5 },
    'Simon Commission': { position:  [-122, 7, 32], zoom: 1 },
    'Bhagat Singh': { position: [-110, 10, -175], zoom: 0.7 },
    'Dandi March': { position: [-157, 10, -56], zoom: 2 },
    'Subhas Chandra Bose': { position: [115, 20, 0], zoom: 1 },
    'August Offer': { position: [-75, 15, 9], zoom: 1.6 },
    'Quit India Movement': { position: [-175, 17, 34], zoom: 2.5 },
    'Azad Hind Fauj': { position: [0, 100, -400], zoom: 1 },
    'Battle of Imphal': { position: [195, 5, -80], zoom: 1.2 },
    'Independence ': { position: [20, 320, 200], zoom: 1 },
    // ... (keep other event settings)
    'default': { position: [0, 0, 200], zoom: 1 }
  };

  useImperativeHandle(ref, () => ({
    getCameraSettings: (eventName) => {
      return eventSettings[eventName] || eventSettings.default;
    }
  }));

  return (
    <>
      <IndiaMapBase position={[0, 0, -10]} />
      {Object.keys(eventData).map((eventName) => (
        <Event 
          key={eventName} 
          name={eventName} 
        />
      ))}
    </>
  );
});

export default FreedomStruggleTimeline;