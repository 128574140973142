import React from 'react';
import { Box, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { buttonColors } from '../../data';

const CardButtons = ({ buttons, onButtonClick, isMobile }) => {
  return (
    <Box sx={{ 
      flex: 3, 
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center',
      pl: isMobile ? 0 : 4,
      mt: isMobile ? 2 : 0,
    }}>
      {buttons.map((buttonText, index) => (
        <Button
          key={index}
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          sx={{
            mb: 1,
            backgroundColor: buttonColors[buttonText],
            '&:hover': {
              backgroundColor: buttonColors[buttonText],
              opacity: 0.8,
            },
            justifyContent: 'space-between',
            paddingRight: 2,
          }}
          onClick={(event) => onButtonClick(buttonText, event)}
        >
          {buttonText}
        </Button>
      ))}
    </Box>
  );
};

export default CardButtons;