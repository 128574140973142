   
import React, { useState, useEffect, useRef } from 'react';

const EventNavCard = ({ onEventSelect, currentEventIndex }) => {
    const [isOpen, setIsOpen] = useState(true);
    const audioRef = useRef(null);

    const events = [
        { name: 'British in India', year: 1608, color: '#FFFFFF', audio: '/music/Shadows of Dissent.mp3' },
        { name: 'Dalhousie and the Doctrine of Lapse', year: 1848, color: '#FFFFFF', audio: '/music/Dalhousie and the Doctrine of Lapse.mp3' },
        { name: 'Mangal Pandey', year: 1857, color: '#FFFFFF', audio: '/music/Mangal Pandey.mp3' },        
        { name: 'The Delhi Attack of 1857', year: 1608, color: '#FFFFFF', audio: '/music/The Delhi Attack of 1857.mp3' },
        { name: 'The Kanpur Attack', year: 1848, color: '#FFFFFF', audio: '/music/The Delhi Attack of 1857.mp3' },
        { name: 'The Lucknow Attack', year: 1857, color: '#FFFFFF', audio: '/music/The Lucknow Attack.mp3' },
        { name: 'Annexation of Jhansi', year: 1608, color: '#FFFFFF', audio: '/music/Annexation of Jhansi.mp3' },
        { name: 'Bihar Attack: Impact', year: 1848, color: '#FFFFFF', audio: '/music/Bihar Attack Impact.mp3' },
        { name: 'East India Company to British Raj', year: 1857, color: '#FFFFFF', audio: '/music/East India Company to British Raj.mp3' },
        { name: 'Indian National Congress (1885)', year: 1608, color: '#FFFFFF', audio: '/music/Indian National Congress (1885).mp3' },
        { name: 'The Partition of Bengal (1905)', year: 1848, color: '#FFFFFF', audio: '/music/The Partition of Bengal (1905).mp3' },
        { name: 'The Swadeshi Movement:', year: 1857, color: '#FFFFFF', audio: '/music/The Swadeshi Movement.mp3' },
        { name: 'National Congress session in Banaras', year: 1608, color: '#FFFFFF', audio: '/music/National Congress session in Banaras.mp3' },
        { name: 'The Shimla Deputation ', year: 1848, color: '#FFFFFF', audio: '/music/The Shimla Deputation.mp3' },
        { name: 'The 1907 Congress Split ', year: 1857, color: '#FFFFFF', audio: '/music/The 1907 Congress Split.mp3' },
        { name: 'The Morley-Minto Reforms (1909)', year: 1857, color: '#FFFFFF', audio: '/music/Dalhousie and the Doctrine of Lapse.mp3' },
        // { name: 'The Swaraj Movement ', year: 1857, color: '#FFFFFF', audio: '/music/The Swadeshi Movement.mp3' },
        { name: 'The Lucknow Pact (1916)', year: 1857, color: '#FFFFFF', audio: '/music/The Lucknow Pact (1916).mp3' },
        { name: 'Satyagraha (1917)', year: 1857, color: '#FFFFFF', audio: '/music/Resilient Harmony.mp3' },
        { name: 'The Kheda Satyagraha (1918)', year: 1857, color: '#FFFFFF', audio: '/music/Resilient Harmony.mp3' },
        { name: 'Rowlatt Act: Jallianwala Bagh Massacre', year: 1857, color: '#FFFFFF', audio: '/music/Echoes of Memory.mp3' },
        { name: 'The Non-Cooperation Movement (1920-1922)', year: 1857, color: '#FFFFFF', audio: '/music/The Non-Cooperation Movement (1920-1922).mp3' },
        { name: 'Chauri Chaura Incident', year: 1857, color: '#FFFFFF', audio: '/music/Chauri Chaura Incident.mp3' },
        { name: 'Hindustan Republican Association (HRA)', year: 1857, color: '#FFFFFF', audio: '/music/Hindustan Republican Association (HRA).mp3' },
        { name: 'Simon Commission', year: 1857, color: '#FFFFFF', audio: '/music/The 1907 Congress Split.mp3' },
        { name: 'Bhagat Singh', year: 1857, color: '#FFFFFF', audio: '/music/Annexation of Jhansi.mp3' },
        { name: 'Dandi March', year: 1857, color: '#FFFFFF', audio: '/music/Echoes of Memory.mp3' },
        { name: 'Subhas Chandra Bose', year: 1857, color: '#FFFFFF', audio: '/music/Mangal Pandey.mp3' },
        { name: 'August Offer', year: 1857, color: '#FFFFFF', audio: '/music/Dalhousie and the Doctrine of Lapse.mp3' },
        { name: 'Quit India Movement', year: 1857, color: '#FFFFFF', audio: '/music/Quit India Movement.mp3' },
        { name: 'Azad Hind Fauj', year: 1857, color: '#FFFFFF', audio: '/music/Azad Hind Fauj.mp3' },
        { name: 'Battle of Imphal', year: 1857, color: '#FFFFFF', audio: '/music/Independence.mp3' },
        
        
        { name: 'Independence ', year: 1947, color: '#FFFFFF', audio: '/music/independence.mp3' }
    ];

    useEffect(() => {
        // Initialize audio element
        audioRef.current = new Audio();
        audioRef.current.loop = true;

        // Cleanup function
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.src = '';
            }
        };
    }, []);

    useEffect(() => {
        // Handle audio playback when currentEventIndex changes
        const currentEvent = events[currentEventIndex];
        if (currentEvent && audioRef.current) {
            audioRef.current.src = `https://kshira-sagara.s3.ap-south-1.amazonaws.com/landing-page/social${currentEvent.audio}`;
            audioRef.current.play().catch(e => console.error("Audio play failed:", e));
        }

        // Cleanup function to stop audio when component unmounts or currentEventIndex changes
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        };
    }, [currentEventIndex]);

    const handleEventClick = (event, index) => {
        if (audioRef.current) {
            audioRef.current.src = event.audio;
            audioRef.current.play().catch(e => console.error("Audio play failed:", e));
        }
        onEventSelect(event.name);
    };

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: isOpen ? 0 : '-220px',
            bottom: 0,
            width: '220px',
            background: 'rgba(0, 0, 0, 0.8)',
            transition: 'left 0.3s ease-in-out',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
        }}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '-30px',
                    width: '30px',
                    height: '60px',
                    background: 'rgba(0, 0, 0, 0.8)',
                    color: 'white',
                    border: 'none',
                    borderRadius: '0 5px 5px 0',
                    cursor: 'pointer',
                }}
            >
                {isOpen ? '◄' : '►'}
            </button>
            <div style={{
                padding: '10px',
                color: 'white',
                fontWeight: 'bold',
                borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
            }}>
                Freedom Struggle Events
            </div>
            <div style={{
                flexGrow: 1,
                overflowY: 'auto',
                padding: '10px',
            }}>
                {events.map((event, index) => (
                    <button
                        key={event.name}
                        onClick={() => handleEventClick(event, index)}
                        style={{
                            width: '100%',
                            padding: '8px',
                            marginBottom: '5px',
                            background: index === currentEventIndex ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.1)',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            fontSize: '11px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            transition: 'background 0.2s',
                            textAlign: 'left',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {event.name} ({event.year})
                    </button>
                ))}
            </div>
        </div>
    );
};

export default EventNavCard;