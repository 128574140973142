import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import { Button, Typography, Box, TextField } from '@mui/material';

const ROD_COUNT = 13;
const BEAD_COUNT_BOTTOM = 4;
const BEAD_COUNT_TOP = 1;
const BEAD_RADIUS = 0.15;
const BEAD_HEIGHT = 0.2;
const ROD_COLOR = '#8B4513';
const BEAD_COLOR = '#FFA500';
const FRAME_COLOR = '#8B4513';
const RECKONING_BAR_COLOR = '#D2691E';

const Bead = ({ position, isActive, section }) => {
  const mesh = useRef();

  useFrame(() => {
    if (section === 'top') {
      mesh.current.position.y = THREE.MathUtils.lerp(
        mesh.current.position.y,
        isActive ? position[1] - 0.25 : position[1],
        0.1
      );
    } else {
      mesh.current.position.y = THREE.MathUtils.lerp(
        mesh.current.position.y,
        isActive ? position[1] + 0.25 : position[1],
        0.1
      );
    }
  });

  return (
    <mesh ref={mesh} position={position}>
      <coneGeometry args={[BEAD_RADIUS, BEAD_HEIGHT, 32]} />
      <meshStandardMaterial color={BEAD_COLOR} />
    </mesh>
  );
};

const Rod = ({ position, beads }) => {
  return (
    <group position={position}>
      <mesh>
        <cylinderGeometry args={[0.02, 0.02, 4, 32]} />
        <meshStandardMaterial color={ROD_COLOR} />
      </mesh>
      {beads.top.map((isActive, index) => (
        <Bead
          key={`top-${index}`}
          position={[0, 1.8, 0]}
          isActive={isActive}
          section="top"
        />
      ))}
      {beads.bottom.map((isActive, index) => (
        <Bead
          key={`bottom-${index}`}
          position={[0, -1.8 + index * 0.25, 0]}
          isActive={isActive}
          section="bottom"
        />
      ))}
    </group>
  );
};

const Frame = () => {
  return (
    <group>
      <mesh position={[0, 2.1, 0]}>
        <boxGeometry args={[7, 0.2, 0.5]} />
        <meshStandardMaterial color={FRAME_COLOR} />
      </mesh>
      <mesh position={[0, -2.1, 0]}>
        <boxGeometry args={[7, 0.2, 0.5]} />
        <meshStandardMaterial color={FRAME_COLOR} />
      </mesh>
      <mesh position={[-3.4, 0, 0]}>
        <boxGeometry args={[0.2, 4.4, 0.5]} />
        <meshStandardMaterial color={FRAME_COLOR} />
      </mesh>
      <mesh position={[3.4, 0, 0]}>
        <boxGeometry args={[0.2, 4.4, 0.5]} />
        <meshStandardMaterial color={FRAME_COLOR} />
      </mesh>
      <mesh position={[0, 0, 0]}>
        <boxGeometry args={[6.6, 0.1, 0.5]} />
        <meshStandardMaterial color={RECKONING_BAR_COLOR} />
      </mesh>
    </group>
  );
};

const AbacusScene = ({ beadPositions }) => {
  const { camera } = useThree();

  useEffect(() => {
    camera.position.set(0, 0, 8);
  }, [camera]);

  return (
    <>
      <ambientLight intensity={0.7} />
      <pointLight position={[10, 10, 10]} intensity={0.5} />
      <Frame />
      {beadPositions.map((beads, index) => (
        <Rod
          key={index}
          position={[-3 + index * 0.5, 0, 0]}
          beads={beads}
        />
      ))}
      <OrbitControls enableZoom={false} enablePan={false} />
    </>
  );
};

const AbacusSimulator3D = () => {
  const [beadPositions, setBeadPositions] = useState(
    Array(ROD_COUNT).fill().map(() => ({
      top: Array(BEAD_COUNT_TOP).fill(false),
      bottom: Array(BEAD_COUNT_BOTTOM).fill(false)
    }))
  );
  const [inputValue, setInputValue] = useState('');

  const updateAbacus = (value) => {
    const newPositions = Array(ROD_COUNT).fill().map(() => ({
      top: Array(BEAD_COUNT_TOP).fill(false),
      bottom: Array(BEAD_COUNT_BOTTOM).fill(false)
    }));

    let remainingValue = value;
    for (let i = ROD_COUNT - 1; i >= 0; i--) {
      const rodValue = remainingValue % 10;
      remainingValue = Math.floor(remainingValue / 10);

      if (rodValue >= 5) {
        newPositions[i].top[0] = true; // Move heaven bead down for 5 and above
      }
      for (let j = 0; j < Math.min(rodValue % 5, BEAD_COUNT_BOTTOM); j++) {
        newPositions[i].bottom[j] = true; // Move earth beads up
      }
    }

    setBeadPositions(newPositions);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value === '') {
      resetAbacus();
    } else {
      const numValue = parseInt(value, 10);
      if (!isNaN(numValue) && numValue >= 0) {
        updateAbacus(numValue);
      }
    }
  };

  const resetAbacus = () => {
    setBeadPositions(
      Array(ROD_COUNT).fill().map(() => ({
        top: Array(BEAD_COUNT_TOP).fill(false),
        bottom: Array(BEAD_COUNT_BOTTOM).fill(false)
      }))
    );
    setInputValue('');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
      <Typography variant="h4" gutterBottom>3D Abacus Simulator</Typography>
      <Box sx={{ width: '800px', height: '400px', border: '1px solid #ccc', marginBottom: '20px' }}>
        <Canvas>
          <color attach="background" args={['white']} />
          <AbacusScene beadPositions={beadPositions} />
        </Canvas>
      </Box>
      <TextField
        label="Enter a number"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        type="number"
        sx={{ marginBottom: '20px', width: '200px' }}
      />
      <Button variant="contained" color="primary" onClick={resetAbacus}>
        Reset Abacus
      </Button>
    </Box>
  );
};

export default AbacusSimulator3D;