import React, { useState } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Box } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Header from './components/Header/Header';
import Card from './components/Card/Card';
import ModalOverlay from './components/Card/ModalOverlay';
import { headerItems, contentData } from './data';
import { buttonColors } from './data/buttonColors';
import ScienceLearningRoom from './components/pages/FreedomStruggle/ScienceLearningRoom';
import SetTheoryWorld from './components/pages/setstheory/SetTheoryWorld'
import PeriodicTableExplorer from './components/pages/chemistry/PeriodicTableExplorerRoom'
import AbacusSimulator3D from './components/pages/numberstheroy/AbacusSimulator3D'
import ScienceLearningRoom1 from './components/pages/solarsystem/components/ScienceLearningRoom'
const theme = createTheme({
  palette: {
    primary: {
      main: '#1F3D58',
    },
    background: {
      default: '#1F3D58',
    },
  },
});

const AppContent = () => {
  const [selectedHeader, setSelectedHeader] = useState(headerItems[0]);
  const [activeButton, setActiveButton] = useState(null);
  const navigate = useNavigate();

  const handleHeaderClick = (header) => {
    console.log('Header clicked:', header);
    setSelectedHeader(header);
    setActiveButton(null);
  };

  const handleButtonClick = (button) => {
    console.log('Button clicked in App:', button);
    if (button === 'SOCIAL') {
      navigate('/social');
    };
    if (button === 'MATHS') {
      console.log('clicked maths')
      navigate('/maths');
    };
    if (button === 'SCIENCE'){
      console.log('clicked inside science')
      navigate('/science')
    }
    setActiveButton(button);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout selectedHeader={selectedHeader} onHeaderClick={handleHeaderClick}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          minHeight: 'calc(100vh - 64px)', // Adjust based on your header height
          position: 'relative',
        }}>
          <Box sx={{ 
            flex: 1,
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            mt: 4,
            position: 'relative',
            zIndex: 5,
            pointerEvents: 'auto',
          }}>
            <Card 
              selectedHeader={selectedHeader} 
              onButtonClick={handleButtonClick}
              activeButton={activeButton}
            />
          </Box>
        </Box>
        {activeButton && contentData[selectedHeader] && contentData[selectedHeader].modalContent && (
          <ModalOverlay 
            content={contentData[selectedHeader].modalContent[activeButton]}
            onClose={() => setActiveButton(null)}
            buttonColor={buttonColors[activeButton]}
          />
        )}
      </Layout>
    </ThemeProvider>
  );
};


const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<AppContent />} />
          <Route path="/social" element={<ScienceLearningRoom />} />
          <Route path="/maths" element={<AbacusSimulator3D/>}/>
          <Route path="/science" element = {<PeriodicTableExplorer/>}/>

        </Routes>
      </ThemeProvider>
    </Router>
  );
};

export default App;