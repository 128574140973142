import React from 'react';
import { Paper, Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { contentData } from '../../data';
import AboutUsContent from './AboutUsContent';
import CardButtons from './CardButtons';

const Card = ({ selectedHeader, onButtonClick, activeButton }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const content = contentData[selectedHeader];
  
  if (!content) {
    console.error(`No content found for header: ${selectedHeader}`);
    return <div>No content available for {selectedHeader}</div>;
  }

  const { headerText, cardContent, buttons } = content;
  const isAboutUs = selectedHeader === 'ABOUT US';

  return (
    <Paper elevation={10} sx={{ 
      width: isMobile ? '90%' : '50%', 
      minWidth: '300px',
      height: '50vh', 
      display: 'flex', 
      flexDirection: 'column',
      borderRadius: '20px',
      overflow: 'visible',
      backgroundColor: 'white',
      position: 'relative',
      zIndex: 10,
    }}>
      <Box sx={{ 
        flex: 1,
        display: 'flex', 
        flexDirection: isAboutUs ? 'column' : (isMobile ? 'column' : 'row'),
        p: 3,
      }}>
        <Box sx={{ 
          flex: isAboutUs ? 1 : 7, 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: isAboutUs ? 'space-between' : 'center', 
        }}>
          <Typography variant="h5" component="h2" sx={{ mb: 2, color: theme.palette.primary.main }}>
            {headerText}
          </Typography>
          <Typography variant="body1" component="div" sx={{ mb: 2 }}>
            {cardContent}
          </Typography>
          {isAboutUs && <AboutUsContent onButtonClick={onButtonClick} />}
        </Box>
        {!isAboutUs && (
          <CardButtons 
            buttons={buttons} 
            onButtonClick={onButtonClick} 
            isMobile={isMobile} 
          />
        )}
      </Box>
    </Paper>
  );
};

export default Card;